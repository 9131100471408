import React from 'react';
import '../styles/greenBurialInfo.css'

function DeathDoulaCareer() {
  return (
    <div className="green-burial-info">
      <h1>Exploring the Death Doula Career Path</h1>
      <p>
        The end-of-life caregiving space is rapidly expanding to meet the needs of a population that is not only aging, but also embracing spirituality and a return to more intimate connection with death. Consider that 10,000 baby boomers are turning 65 every day in America alone, and though lifespans are generally increasing, healthspans are not.
      </p>
      <p>
        If this information piques your interest or you’ve been asking yourself questions like “How do I become a death doula?” or “Do I need to be a certified advance care planning specialist?” then this is a good time to start really considering your options for helping advance the Aging Well and Deathcaring movements.
      </p>

      <h2>How To Become a Death Doula</h2>
      <p>
        To begin, let’s explain what a death doula. Note, too, that “death doulas” may also go by terms such as “death companion,” “death navigator” or “death midwife.”
      </p>
      <p>
        A death doula is a non-clinical individual who offers community with an individual at the end of life. Death doulas do not administer medicines or manage physical care, but rather attend to more social, emotional, and spiritual matters for clients and their families.
      </p>
      <p>
        As such, community can manifest in a wide range of potential services and benefits. Some doulas focus in advance care planning, while others may offer vigil or eleventh hour bedside services during which time they sit with clients and their families through the active dying process. Many death doulas are also trained in legacy work and can help families create crafts or narratives that help memorialize their loved one while also offering grief processing support.
      </p>
      <p>
        Many death doulas are also now available for support in pet loss matters, helping pet owners create ritual around the death of a beloved dog, cat, or other animal.
      </p>
      <p>
        Because death doulas are non-clinical, it is not necessary to come from a formal healthcare background or possess a degree such as a licensed nurse practitioner (LNP) or registered nurse (RN) in order to act as a death doula. Still, it’s common to meet many fellow death doulas who do hail from medicine and were motivated to help fill in healthcare gaps around end-of-life care. Similarly, you do not need a formal chaplaincy background in order to become a death doula.
      </p>
      <p>
        Death doulas are not currently licensed in any of the 50 states and, as such, there is no one certification that is necessary. However, death doula training is an important preparatory commitment to serving individuals in navigating the many social, emotional, logistical, and spiritual nuances that can arrive during end of life.
      </p>
      <p>
        Some death doula training programs take place over extended weekends, while others require a more substantial commitment in the form of apprenticeships. Some trainings are offered in-person, while others can be accessed remotely, often through a combination of synchronous and asynchronous course curriculum.
      </p>
      <h3>Death Doula Training and Apprentice Programs:</h3>
      <ul>
        <li><a href="https://deathwives.org/">DeathWives Death School Training</a></li>
        <li><a href="https://learn.uvm.edu/program/end-of-life-doula-certificate/?gad_source=1&gclid=CjwKCAiAg9urBhB_EiwAgw88mT-J3tHLAsfHJ07Zr0KpCQnFE_OtL6UzhZXBCgiRZXHCtDRWkOhsMxoCeGcQAvD_BwE&gclsrc=aw.ds">University of Vermont</a></li>
        <li><a href="https://goingwithgrace.com/trainingdetails/">Going with Grace</a></li>
        <li><a href="https://inelda.org/">International End of Life Doula Association (INELDA)</a></li>
        <li><a href="https://www.narinderbazen.com/death-midwifery-training">Nine Keys Death Midwifery Training</a></li>
        <li><a href="https://www.doulagivers.com/home-launch/">Doulagivers Institute</a></li>
      </ul>
      <p>
        Once you’ve completed appropriate training, either formally or informally, and are feeling comfortable with the end-of-life policies in your specific state, you may consider further “practice” by shadowing a more experienced doula. Alternatively, some hospices allow beginning death doulas to offer their services on a volunteer basis to the families they serve.
      </p>
      <p>
        This is also a crucial time to network with other end-of-life care practitioners in your region. You might consider going to Death Cafes or Death Over Drafts experiences or engaging with other adjacent membership organizations such as the National Council on Aging, the American Society on Aging, National Home Funeral Alliance, Green Burial Council, and Funeral Consumers Alliance. In addition to networking, many of these organizations offer informative webinars and continuing education opportunities at highly accessible price points.
      </p>
    </div>
  );
}

export default DeathDoulaCareer;
