import {useState, useEffect} from "react"
import { useParams } from "react-router-dom"
import {useLogout} from '../hooks/useLogout'
import { useAuthContext } from "../hooks/useAuthContext";
import {Link, useNavigate} from "react-router-dom"
import '../styles/userProfile.css'

const UserProfile = () => {
    const {user} = useAuthContext()
    const navigate = useNavigate();
	const {logout} = useLogout()
    const handleDelete = async(e) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/user/'+user.userId, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        })
        const json = await response.json()
        
        if(!response.ok) {
          console.log("Unable to delete profile")
          console.log("error: ", json.error)
        }
        else{
            logout()
            navigate("/");
        }
      }

    return(
        <div className="user-profile">
            <h1>Hello {user.username}!</h1>
            <h2>User Email: {user.email}</h2>
            <br></br>
            <button
                className="profileDeletion"
                onClick={() => {
                    if (window.confirm("Are you sure you want to delete your profile? This action is irreversible.")) {
                        handleDelete()
                    }
                }}
            >
                Delete User Profile
            </button>
            <h1></h1>
        </div>
    )
}

export default UserProfile