import { Link } from "react-router-dom"
import Post from "../components/Post"
import { usePostContext } from "../hooks/usePostsContext"
import {useEffect, useState} from "react"
const Blog = () => {
    const {posts, dispatch} = usePostContext()
    const [filterType, setFilterType] = useState('New');
    const [mainFilter, setMainFilter] = useState('New');  // New state for the main filter
    const [searchTerm, setSearchTerm] = useState('');

    
    useEffect(()=>{
        const fetchPosts = async() => {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/posts')
            const json = await response.json()
            
            if (response.ok) {
                dispatch({type: 'SET_POSTS', payload:json})
            }
        }

        fetchPosts()
    }, [dispatch])
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 5;

    // Calculate the total number of pages
    const totalPages = Math.ceil(posts.length / postsPerPage);

    // Function to update the current page
    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Get the posts for the current page
    const currentPosts = posts.slice((currentPage - 1) * postsPerPage, currentPage * postsPerPage);

    const handleFilterChange = async(type) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/posts')
        let json = await response.json()

        if (response.ok) {
            dispatch({type: 'SET_POSTS', payload:json})
        }

        console.log("Type: ", type)
        if (type==undefined || !type) {
            type = "Top today";  // Default to "Top today" if no specific top filter is passed
        }


        dispatch({
            type: 'FILTER_POST',
            filterType: type, payload:json
        });
    };

    const handleSearch = async() => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/posts')
        let json = await response.json()

        if (response.ok) {
            dispatch({type: 'SET_POSTS', payload:json})
        }
        dispatch({
            type: 'SEARCH_POSTS',
            payload: searchTerm
        });
    }
    
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch(); // This function will contain the logic to search posts.
        }
    }

    return(
        <div className="blog-main">
            <h1>Clio Community Forum</h1>
            <div className="welcome-description">
                <p>Welcome to We Are Clio’s Community Forum. Here you’re welcome and encouraged to submit questions and share ideas with our growing community of caregivers and death workers. We’d love for you to take a moment to <Link to="/terms-and-conditions">peruse our Terms & Conditions</Link>, but for those in a hurry, the TLDR version is that we expect it to be family friendly and supportive. If you wouldn’t say it out loud at a Death Cafe, please don’t post it here.</p>
            </div>

            <div className="search-section">
                <input 
                    type="text" 
                    className="search-input" 
                    placeholder="Search for posts..." 
                    value={searchTerm} 
                    onChange={e => setSearchTerm(e.target.value)} 
                    onKeyDown={handleKeyDown}

                />
                <button className="search-btn" onClick={handleSearch}>Search</button>
            </div>

            <div className="filter-section">
                <div className="filter-controls">
                    <div className="filter-group">  
                        <label htmlFor="mainFilter" className="filter-label">Sort by: </label>
                        <select value={mainFilter} onChange={(e) => {
                            setMainFilter(e.target.value);
                            if (e.target.value === "New") {
                                handleFilterChange("New");
                            } else if (e.target.value === "Top") {
                                setFilterType("Top today");
                                handleFilterChange();
                            }
                        }}>
                            <option value="New">New</option>
                            <option value="Top">Top</option>
                        </select>
                    </div>

                    {mainFilter === "Top" && (
                        <div className="filter-group">
                            <label htmlFor="filterType" className="filter-label">Time frame: </label>
                            <select value={filterType} onChange={(e) => {
                                setFilterType(e.target.value);
                                handleFilterChange(e.target.value);
                            }}>
                                <option value="Top today">Today</option>
                                <option value="Top this week">This week</option>
                                <option value="Top this month">This month</option>
                                <option value="Top this year">This year</option>
                                <option value="Top all time">All time</option>
                            </select>
                        </div>
                    )}
                </div>
                <div className="create-post-section">
                    <Link to="/create-blog-post" className="create-post-btn">Create Post</Link>
                </div>
            </div>


            <div className="posts">
                {currentPosts && currentPosts.length > 0 ? (
                    currentPosts.map((post) => (
                        <Post key={post._id} post={post} />
                    ))
                ) : (
                    <p>No Such Post Exists - start the discussion by making one of your own :)</p>
                )}

                {/* Pagination Controls */}
                <div className="pagination-controls">
                    {/* Previous Page */}
                    {currentPage > 1 && <button onClick={() => goToPage(currentPage - 1)}>Prev</button>}

                    {/* Page Numbers */}
                    {Array.from({ length: totalPages }).map((_, index) => (
                        <button key={index} onClick={() => goToPage(index + 1)} className={currentPage === index + 1 ? 'active' : ''}>
                            {index + 1}
                        </button>
                    ))}

                    {/* Next Page */}
                    {currentPage < totalPages && <button onClick={() => goToPage(currentPage + 1)}>Next</button>}
                </div>
            </div>
        </div>
        
        
        
    )
}

export default Blog