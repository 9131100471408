import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {ListingsContextProvider} from './context/ListingContext'
import { PostContextProvider } from './context/PostContext';
import { AuthContextProvider } from './context/AuthContext';
import { CommentContextProvider } from './context/CommentContext';
import { ReplyContextProvider } from './context/ReplyContext';
import { PasswordProvider } from './context/PasswordContext';
import { EventsContextProvider } from './context/EventContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PasswordProvider>
    <AuthContextProvider>
      <ListingsContextProvider>
      <PostContextProvider>
      <CommentContextProvider>
      <ReplyContextProvider>
      <EventsContextProvider>
        <App />
      </EventsContextProvider>
      </ReplyContextProvider>
      </CommentContextProvider>
      </PostContextProvider>
      </ListingsContextProvider>
    </AuthContextProvider>
    </PasswordProvider>
  </React.StrictMode>
);
