import { useState } from 'react'
const Help = () => {
   
   

    return (
        
        <div style={styles.container}>
            <h1 style={styles.header}>Feeling stuck?</h1>
            <p>Please refer to answers to some of our most frequently asked questions below to quickly resolve issues. If you’re still having trouble finding a solution, don’t hesitate to email us at <a href="mailto:Services@WeAreClio.com">Services@WeAreClio.com</a> and a team member will connect with you within 48 hours.</p>

            <h2 style={styles.subHeader}>How do I list my service?</h2>
            <p>In order to list your service, you’ll first need to create an account. Once you have an account, simply click “List My Service” and a new page will appear with all of the available profile fields.</p>

            <h2 style={styles.subHeader}>How do I edit or delete my service listing?</h2>
            <p>To edit your service, simply login and then click “list your service.” Your active listing will appear for you to edit or delete.</p>

            <h2 style={styles.subHeader}>Why does my profile photo appear skewed or off-center?</h2>
            <p>We recommend uploading a high resolution profile image that is 720 pixels x 720 pixels with an aspect ratio of 1:1.</p>

            <h2 style={styles.subHeader}>How do I know if people are viewing my profile?</h2>
            <p>Currently, interested clients are encouraged to email you directly. However, we are working on a more robust profile analytics solution that will be made available in the months ahead.</p>

            <h2 style={styles.subHeader}>Why aren’t there any service providers showing for my provider search?</h2>
            <p>Our platform is growing by the day, so please continue to check back! Unfortunately, we also must acknowledge that some communities still do not have a wide pool of end-of-life practitioners for in-person care. To that end, we always encourage exploring providers who can offer virtual support.</p>

            <h2 style={styles.subHeader}>How do I know if a provider is right for me?</h2>
            <p>Many end-of-life care providers operate websites with even more information and/or provide consultation calls that offer a good introduction for you to then make a more informed decision about. Remember that you are responsible for vetting any provider you choose. It’s always a good idea to ask them how long they have been practicing, how many clients they have served, and if they have references you can speak with.</p>
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start', // Align content to the top
        minHeight: '100vh',
        backgroundColor: '#f7f7f7',
        padding: '20px',
        paddingTop: '40px' // Add some padding on top to move it slightly down but still closer to the top
    },
    header: {
        fontSize: '2.5em',
        fontWeight: 'bold',
        color: '#333'
    },
    subHeader: {
        fontSize: '1.5em',
        marginBottom: '20px',
        color: '#555'
    },
    form: {
        width: '80%',
        maxWidth: '500px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    emailInput: {
        width: '100%',
        padding: '10px 15px',
        fontSize: '1em',
        margin: '10px 0',
        borderRadius: '4px',
        border: '1px solid #ccc'
    },
    buttonContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
    },
    submitButton: {
        backgroundColor: '#007BFF',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '1em'
    }
}



export default Help;
