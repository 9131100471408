import React from 'react';
import '../styles/greenBurialInfo.css'

function GreenBurialInfo() {
    return (
        <div className="green-burial-info">
            <h1>Embracing Eco-Friendly End-of-Life Practices</h1>
            <p>The movement towards environmentally sustainable practices has extended to the way we approach end-of-life disposition and rituals. In 2023, the <a href="https://nfda.org/news/statistics">NFDA consumer awareness report</a> found that 60% are interested in exploring green burial options, up more than 4% from 2021. In both the United States and Canada, this growing interest in green or ecologically-friendly burials has sparked the re-emergence of artisans and craftspeople dedicated to creating eco-friendly burial options such as hand hewn caskets of locally sourced wood, woven willow caskets, and eco-friendly shrouds.</p>
            <p>These individuals contribute significantly not only to the development and availability of sustainable burial methods, but also often incorporate the family into the very creation process itself. This healing and even therapeutic approach allows the dying person and their loved ones to process the loss through collective movement and creativity.   </p>

            <h2>Environmental Benefits of Green Burial</h2>
            {/* ... Content for environmental benefits ... */}
            <p>Compared to fire cremation and traditional vault burials, green burials have notable environmental advantages. Green burials have a significantly lower carbon footprint than cremation, which releases carbon dioxide and other pollutants into the atmosphere. Additionally, green burials omit the use of formaldehyde embalming fluids which also pose a risk to the environment. Finally, green burial promotes the use of locally sourced and biodegradable materials, minimizing the environmental impact on land use and preserving natural resources.</p>
            <p>Green Burial is becoming so popular that Intuit’s Mailchimp & VICE collaborated to feature green burial maker Pāhiki Eco-Caskets in 2020 as part of their Second Act series.</p>
            <div className="video-container">
                {/* Linking to the video with a thumbnail */}
                <iframe 
                src="https://player.vimeo.com/video/765054941?h=439c6282a3" 
                width="640" 
                height="360" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowFullScreen
                title="SECOND ACT | PAHIKI ECO CASKETS">
            </iframe>
            </div>

            <h2>Is Green Burial Legal in the US?</h2>
            {/* ... Content for legal status ... */}
            <p>Green burials are legal across all of the United States, although the regulations regarding green burial practices might differ from state to state, and some states simply do not yet have facilities offering green burial. However, the growing demand for eco-friendly alternatives has prompted more existing cemeteries to consider accommodating green burial practices within their existing legal frameworks. </p>

            <p>Likewise, many environmentalists and conservationists are increasingly interested in the opportunities for natural burial sites which not only prohibit the use of herbicides and pesticides in their management, but also help restore wildlife. If you are someone who is passionate about starting a green burial cemetery or expanding access to natural burial, consider learning more about <a href="https://www.greenburialcouncil.org/">Green Burial Council</a> or <a href="https://greenburialcanada.ca/">Green Burial Society of Canada</a>.</p>

            <h2>What are Common Myths of Green Burial?</h2>
            <p>As green burial is just now re-emerging and the funeral industry is a big business, there is a lot of misinformation and disinformation presently circulating around green burial. Below we quickly dispel some of the most common urban legends about eco-friendly disposition.</p>

            <h3>Myth #1: Animals will dig up the body.</h3>
            <p>In a green or natural burial, not only is the body still contained by a thick shroud and/or biodegradable casket, but bodies are also buried with at least an 18-inch smell barrier and between three to four and a half feet below ground where optimal microbial activity exists. As such, animals are highly unlikely to expend the level of effort needed to reach a decaying body and no such instances have been documented.</p>

            <h3>Myth #2: The water in the area may become contaminated.</h3>
            <p>Potable water is typically retrieved 75 feet underground, far removed from the microbial activity of disposition. What’s more, there are state-regulated mandatory setbacks from water sources then further ensure water safety.</p>

            <h3>Myth #3: Green burial is more expensive.</h3>
            <p>The average cost of a traditional burial in the U.S. is $7,200. While green burial options can also range in price both for burial containers and plots, the simplest of green burials can be conducted with a pre-owned blanket as a shroud and the cost of the plot.</p>

            <h2>Who Prepares the Body for a Green Burial?</h2>
            <p>In the case of green burials, body preparation might be carried out by family members, often with the assistants of a <a href="https://www.homefuneralalliance.org/">home funeral guides</a>, or by professional funeral directors who have received specific training in natural and eco-friendly techniques. Currently, nine states – CT, IL, IA, LA, MI, NE, NJ, NY -  require families to hire a funeral director for all burials, regardless of whether they are green burials or not. These individuals focus on ensuring that the burial aligns with environmental principles, often avoiding embalming and utilizing natural, biodegradable materials. </p>

            <h2>Craftsmanship in Green Burial</h2>
            <p>Artisans involved in crafting green burial materials play a pivotal role in continuing the growth of green burials. Shroud makers utilize natural fabrics like cotton, flax, or linen to create biodegradable shrouds for wrapping the deceased. Similarly, they often rely on locally sourced natural dyes such as those derived from nuts, leaves, and flowers. </p>
            <p>Likewise, willow casket weavers use sustainably sourced materials to craft biodegradable caskets akin in style to a basket. These artisans, many of whom have spent years apprenticing and developing their craft, contribute to providing eco-friendly alternatives for individuals seeking environmentally conscious end-of-life options.</p>
            <div className="video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/wWiPJVtJiAo?si=h0_G3LD7kSdomnfC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                {/* Embedding willow casket video */}
            </div>
            
            <h2>Summary</h2>
            <p>The rising interest in green burials has spurred a growing community of artisans and crafters in the United States, dedicated to providing environmentally sustainable alternatives. These individuals and their creations offer a spiritually rich and eco-conscious way for individuals and their families to approach end-of-life rituals.</p>

        </div>
    );
}

export default GreenBurialInfo;
