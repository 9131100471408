import React, { createContext, useContext, useState } from 'react';

const PasswordContext = createContext();

export const PasswordProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('authToken') === 'jek3l2oocPnemOOpMM$*229Klem#');


    return (
        <PasswordContext.Provider value={[isAuthenticated, setIsAuthenticated]}>
            {children}
        </PasswordContext.Provider>
    );
};

export const usePassword = () => {
    return useContext(PasswordContext);
};