import { useState } from 'react';
import { useSignup } from '../hooks/useSignup';
import { useNavigate } from 'react-router-dom';

const Signup = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [checkable, setCheckable] = useState(true);
    const { signup, error, isLoading } = useSignup();
    const navigate = useNavigate();

    //const preLoginUrl = sessionStorage.getItem('preLoginUrl');
    //console.log("pre log: ", preLoginUrl)

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const signupSuccess = await signup(username, email, password, checkable);
    
        if (signupSuccess) {
            let preLoginUrl = sessionStorage.getItem('preLoginUrl');
            sessionStorage.removeItem('preLoginUrl');
            if (preLoginUrl === '/create-your-deathcare-directory-free-listing') {
                preLoginUrl = '/listservice';
            }
            navigate(preLoginUrl || '/');
        } else {
            console.log("Uh oh")
            // Handle unsuccessful signup, e.g., show error message
        }
    };

    return (
        <form 
            className="signup" 
            onSubmit={handleSubmit}
            method="POST"
        >
            <h3>Sign up</h3>

            <label>Username: </label>
            <input 
                onChange={(e) => setUsername(e.target.value)}
                value={username}
            />

            <label>Email: </label>
            <input 
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
            />
            
            <label>Password: </label>
            <input 
                type="password" 
                onChange={(e) => setPassword(e.target.value)}
                value={password}
            />
            <button disabled={isLoading}>Sign Up</button>

            <div className="checkmark-container">
                <input 
                    type="checkbox" 
                    id="contactConsent" 
                    checked={checkable} 
                    onChange={() => setCheckable(!checkable)} 
                />
                <label htmlFor="contactConsent">I am open to be contacted by journalists and researchers.</label>
            </div>

            <div className="authLink">
                <a href="#" onClick={() => navigate('/login')}>Have an account? Log In</a>
            </div>
            {error && <div className="error">{error}</div>}
        </form>
    );
};

export default Signup;