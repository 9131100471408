import { createContext, useReducer} from "react";

export const ReplyContext = createContext()

//Store reply ids in comments and then print those
export const replyReducer = (state, action) => {
    switch(action.type) {
        case 'SET_REPLIES':
            return {
                ...state,
                replies: {
                    ...state.replies,
                    [action.commentId]: action.payload
                }
            };
        case 'SET_REPLY':
            console.log("comment ID: ", action.payload.commentId)
            const updatedReplyId = action.payload.reply._id;
            const commentIdForUpdatedReply = action.payload.commentId; // Assuming this exists in the payload
            const existingRepliesForUpdatedComment = state.replies[commentIdForUpdatedReply] || [];
            const updatedReplies = existingRepliesForUpdatedComment.map((reply) => {
                if (reply._id === updatedReplyId) return action.payload.reply;
                return reply;
            });
            return {
                ...state,
                replies: {
                    ...state.replies,
                    [commentIdForUpdatedReply]: updatedReplies
                }
            };
        case 'CREATE_REPLY':
            const commentIdForReply = action.payload.commentId; // Assuming this exists in the payload
            const existingRepliesForComment = state.replies[commentIdForReply] || [];
            return {
                ...state,
                replies: {
                    ...state.replies,
                    [commentIdForReply]: [...existingRepliesForComment, action.payload]
                }
            };
        case 'DELETE_REPLY':
            // This logic will need to know which comment's replies to update
            const commentIdForDeletion = action.payload.commentId; // Assuming this exists in the payload
            const updatedRepliesAfterDeletion = (state.replies[commentIdForDeletion] || []).filter(reply => reply._id !== action.payload._id);
            return {
                ...state,
                replies: {
                    ...state.replies,
                    [commentIdForDeletion]: updatedRepliesAfterDeletion
                }
            };
        case 'FILTER_REPLIES':
            return {
                ...state,
                replies: {
                    ...state.replies,
                    [action.commentId]: action.payload
                }
            };
        default:
            return state;
    }
}

export const ReplyContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(replyReducer, {
        replies: {}
    })

    return(
        <ReplyContext.Provider value={{...state, dispatch}}>
            { children }
        </ReplyContext.Provider>
    )
}