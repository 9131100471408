import { useEffect } from 'react';

const About = () => {

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add('show');
            }
          });
        }, { threshold: 0.1 });
    
        const targets = document.querySelectorAll('.scroll-animation');
        targets.forEach(target => observer.observe(target));
      }, []);

    return(
        <div className="about-us">
            <h1 className="about-us-title scroll-animation">About Us</h1>
            <p className="about-us-content scroll-animation">Deathcare is changing. Taboos are loosening, policies are adapting, and individuals of all ages are eager to reclaim this sacred space from industry. We Are Clio was crafted in response to these advancements and designed with one goal in mind: to help the dying more quickly connect with the global deathcaring community ready to serve them.</p>
            <p className="about-us-content scroll-animation">Why? Because we know firsthand that there is a dynamic and growing field of death doulas, herbalists, ritualists, legacy crafters, shroud makers, and more who are positively impacting their clients but who also aren’t super “findable” for the many others outside their direct network. </p>
            <p className="about-us-content scroll-animation">Rather than expecting practitioners to become digital marketing savants and the general population to become overnight masters of death literacy, we’re here to fill in the gap: one community meeting house for one of life’s most tender experiences.  </p>

            <h1 className="about-us-title scroll-animation">Why "Clio?"</h1>
            <p className="about-us-content scroll-animation">Clio is the Greek muse of history, responsible for recording - or not - the stories and accomplishments of those facing end-of-life. Often depicted in flowing robes with open scrolls or tablets, Clio is sometimes referred to as “the proclaimer” and is, in one illustration, seen actively fighting off Father Time. </p>
            <p className="about-us-content scroll-animation">Though Clio is not among the most widely recognized of the Greek mythology characters, we consider her one of the most important because history matters. Death should be person-centered, and the dying should have the opportunity, if they so choose, to dictate their deathly matters in a way that preserves the legacy they seek. </p>
        
            <h1 className="about-us-title scroll-animation">Clio Co-Founders</h1>
            <p className="about-us-content scroll-animation">Anish Dhanasekaran and Dr. Sarah Parker Ward are two unlikely collaborators who met by chance at a university presentation and bonded over their deeply held shared value of person-centered death. Sarah brings more than a decade of digital communications leadership to her ongoing thanatology scholarship, while Anish contributes top-tier technical expertise, having prior technical founding experience and winning multiple awards from a variety of hackathons and competitions. Both are committed to designing a digital platform that is user-friendly, unyieldingly inclusive, and financially independent.  </p>

            <h1 className="about-us-title scroll-animation">Our Commitment to Inclusivity</h1>
            <p className="about-us-content scroll-animation">“There’s a huge difference between ‘all are welcome’ and ‘this was created with you in mind.’” -Dr. Crystal Jones</p>
            <p className="about-us-content scroll-animation">For the cofounders, this platform is personal. We’re committed to ensuring that We Are Clio community members of all ages, races, and creeds feel treasured in this space. We prize the social change ecosystem framework developed by Deepa Iyer, and focus our efforts on the Networker role therein. Furthermore, we assess our resource content through a Justice, Equity, Diversity, and Inclusion (J.E.D.I.) framework and actively monitor both our forum and direct messaging components with a zero-tolerance policy for abusive speech. </p>
            <p className="about-us-content scroll-animation">We also acknowledge that commitment to social change requires an honest, lifelong curiosity and a transparency about our own limits. Will we make mistakes along the way? Maybe. Probably. As a community, we hope you’ll both hold us accountable and extend grace as we work to get it right.  </p>
        </div>
    )
}

export default About