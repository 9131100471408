import { ReplyContext } from '../context/ReplyContext'
import { useContext } from 'react'

export const useRepliesContext = () => {
    const context = useContext(ReplyContext)

    if(!context){
        throw Error('useRepliesContext must be used inside an RepliesContextProvider')
    }

    return context
}