import React, { useState } from 'react';
import {useAuthContext} from "../hooks/useAuthContext"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import { useNavigate } from 'react-router-dom';
import "../styles/calendarForm.css"

const AddEventForm = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [link, setLink] = useState('');
    const [endDate, setEndDate] = useState(new Date());
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone); // Default to user's local timezone
    const [error, setError] =  useState(null)

    const {user} = useAuthContext();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!user){
            setError('You must be logged in to add an event')
            return
        }
        if (!title || title.trim() === '') {
            setError('Title cannot be empty');
            return;
        }

        if(!startTime){
            setError('Start Time cannot be empty');
        }

        if(!endTime){
            setError('End Time cannot be empty');
        }

        if(!timeZone){
            setError('Time Zone cannot be empty');
        }

        const eventData = {
            title, 
            description,
            username:user.username, 
            link,
            startDate: new Date(startDate.toISOString().split('T')[0] + 'T' + startTime).toISOString(),
            endDate: new Date(endDate.toISOString().split('T')[0] + 'T' + endTime).toISOString(),
            timeZone 
        };


        console.log("event: ", Object.keys(eventData))
        const formData = new FormData();
        Object.keys(eventData).forEach(key => {
            console.log("pikachu", key)
            formData.append(key, eventData[key]); // append all the properties of post object to formData
        });
        /*
        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }
        */

        const response = await fetch(process.env.REACT_APP_API_URL + '/api/events', {
            method: 'POST',
            body: formData,
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()

        if(!response.ok) {
            console.log("uh oh events")
            setError(json.error)
            //setEmptyFields(json.emptyFields)
        }

        if(response.ok){
            setError(null)
            //setEmptyFields([])
            
            console.log('new event added')
            navigate('/community-calendar')
            //dispatch({type: 'CREATE_LISTING', payload: json})
        }
        // onEventAdded(); // Callback to update calendar
    };

    return (
        <form className="addCalendarEvent"onSubmit={handleSubmit}>
            <label>*Title:</label>
            <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
            <label>*Start Date:</label>
            <input type="date" value={startDate.toISOString().split('T')[0]} onChange={(e) => setStartDate(new Date(e.target.value))} />
            <label>*Start Time:</label>
            <input type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
            <label>*End Date:</label>
            <input type="date" value={endDate.toISOString().split('T')[0]} onChange={(e) => setEndDate(new Date(e.target.value))} />
            <label>*End Time:</label>
            <input type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
            <label>Description:</label>
            <textarea placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} />
            <label>Event Link:</label>
            <input placeholder="Link to event" value={link} onChange={(e) => setLink(e.target.value)} />
            <button type="submit">Add Event</button>
            {error && <div className="error">{error}</div>}
        </form>
    );
};

export default AddEventForm;

