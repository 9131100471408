import { useState } from "react";
import {useAuthContext} from './useAuthContext'
 
export const useLogin = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const {dispatch} = useAuthContext()

    const login = async(email, password) => {
        setIsLoading(true)
        setError(null)
    
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/user/login', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({email, password})
            })
    
            const json = await response.json()
    
            if (!response.ok) {
                setError(json.error)
                setIsLoading(false)
                return false; // Login failed
            }
    
            if (response.ok) {
                localStorage.setItem('user', JSON.stringify(json))
                dispatch({type: 'LOGIN', payload: json})
                setIsLoading(false)
                return true; // Login successful
            }
        } catch (error) {
            setError("An error occurred during login.")
            setIsLoading(false)
            return false; // Error occurred
        }
    }    

    return {login, isLoading, error}
}