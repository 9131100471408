import {BrowserRouter, Routes, Route, Navigate, useLocation} from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext';

//pages and components
import Home from './pages/home';
import Navbar from './components/Navbar';
import Directory from './pages/directory';
import ListingForm from './components/ListingForm';
import Login from './pages/Login';
import Signup from './pages/Signup';
import { ListingPage } from './pages/ListingPage';
import About from './pages/about';
import Glossory from './pages/glossory';
import Blog from './pages/blog';
import CreatePost from './pages/createPost';
import { PostPage } from './pages/PostPage';
import Help from './pages/help';
import TermsAndConditions from './pages/termsConditions';
//password protection for entire site, remove once demo is unleashed
//import PasswordProtection from './pages/passProtection';
import { usePassword } from './context/PasswordContext';// Import the hook
import Footer from './pages/Footer';
import ListingLanding from './pages/listinglanding';
import GoogleTag from './components/GoogleTag';
import CaregiverResources from './pages/caregiverResources';
import GreenBurialInfo from './pages/GreenBurialInfo';
import DeathDoulaInfo from './pages/deathDoulaInfo';
import CareerContent from './pages/endOfLifeCareers';
import DeathDoulaCareer from './pages/deathDoulaCareer';
import UserProfile from './pages/userProfile';
import CommunityCalendar from './pages/communityCalendar';
import AddEventForm from './components/addCalendarEvent';
import ForgotPassword from './pages/forgotPassword';
import ResetPassword from './pages/resetPassword';

function PageWrapper({ children }) {
  const location = useLocation(); // Hook to get the current location
  const isHomePage = location.pathname === '/'; // Check if it's the home page

  return (
    <div className={isHomePage ? "" : "pages"}>
      {children}
    </div>
  );
}

function App() {
  const {user} = useAuthContext()
  /*
  const [isAuthenticated] = usePassword(); // Destructure the isAuthenticated value

  if (!isAuthenticated) {
    return <PasswordProtection />;
  }
  */
  return (
    <div className="App">
      <GoogleTag />
      <BrowserRouter>
      <Navbar />
        <Routes>
        <Route 
            path="/"
            element={<PageWrapper><Home /></PageWrapper>}
          />
          <Route 
            path="/deathcare-directory"
            element={<PageWrapper><Directory /></PageWrapper>}
          />
          <Route
            path="/about"
            element={<PageWrapper><About /></PageWrapper>}
          />
          <Route 
            path="/death-and-dying-glossary"
            element={<PageWrapper><Glossory/></PageWrapper>}
          />
          <Route
            path="/end-of-life-community-forum"
            element={<PageWrapper><Blog/></PageWrapper>}
          />
          <Route 
            path="create-your-deathcare-directory-free-listing"
            element={<PageWrapper><ListingLanding/></PageWrapper>}
          />
          <Route
            path="/create-blog-post"
            element={user ? <PageWrapper><CreatePost /></PageWrapper> : <Navigate to="/login" />}
          />
          <Route
            path="/add-event"
            element={user ? <PageWrapper><AddEventForm/></PageWrapper> : <Navigate to="/login" />}
          />
          <Route 
            path="/listservice"
            element={user ? <PageWrapper><ListingForm /></PageWrapper> : <Navigate to="/login" />}
          />
          <Route 
            path="/signup"
            element={!user ? <PageWrapper><Signup /></PageWrapper> : <Navigate to="/" />}
          />
          <Route 
            path="/login"
            element={!user ? <PageWrapper><Login /></PageWrapper> : <Navigate to="/" />}
          />
          <Route 
            path="/listing/:listingId"
            element={<PageWrapper><ListingPage/></PageWrapper>}
          />
          <Route 
            path="/post/:postId"
            element={<PageWrapper><PostPage/></PageWrapper>}
          />
          <Route 
            path="/user/:userId"
            element={<PageWrapper><UserProfile/></PageWrapper>}
          />
          <Route
            path="/help"
            element={<PageWrapper><Help/></PageWrapper>}
          />
          <Route
            path="/terms-and-conditions"
            element={<PageWrapper><TermsAndConditions/></PageWrapper>}
          />
          <Route 
            path="/caregiver-resources"
            element={<PageWrapper><CaregiverResources /></PageWrapper>}
          />
          <Route 
            path="/green-burial-info"
            element={<PageWrapper><GreenBurialInfo /></PageWrapper>}
          />
          <Route 
            path="/doula-resources"
            element={<PageWrapper><DeathDoulaInfo /></PageWrapper>}
          />
          <Route 
            path="/eol-careers"
            element={<PageWrapper><CareerContent /></PageWrapper>}
          />
          <Route 
            path="/death-doula-careers"
            element={<PageWrapper><DeathDoulaCareer /></PageWrapper>}
          />
          <Route 
            path="/community-calendar"
            element={<PageWrapper><CommunityCalendar /></PageWrapper>}
          />
          <Route 
            path="/forgot-password"
            element={<PageWrapper><ForgotPassword /></PageWrapper>}
          />
          <Route 
            path="/reset-password/:token"
            element={<PageWrapper><ResetPassword /></PageWrapper>}
          />
        </Routes>
      <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
