import { useListingsContext } from "../hooks/useListingsContext"
import {Link} from "react-router-dom"
const ListingDetails = ({listing}) => {
    const {dispatch } = useListingsContext()

    const handleDelete = async () => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/listings/' + listing._id, {
            method: 'DELETE'
        })
        const json = await response.json()

        if(response.ok){
            dispatch({type:'DELETE_LISTING', payload: json})
        }
    }

    return (
        <Link to={`/listing/${listing._id}`} className="profile-link">
        <div className="listing-details">
            {listing.pfp && <img src={listing.pfp} alt="user profile"/>}
            <div className="listing-text">
                <h4><b>{listing.name}</b></h4>
                {listing.city && listing.city !== "undefined" && listing.state && listing.state !== "undefined" && listing.country !== "undefined" && 
                    <h6 className="user-location">[{listing.city}, {listing.state}]</h6>
                }
                {(!listing.city || listing.city == "undefined") && listing.state && listing.state !== "undefined" && listing.country !== "undefined" && 
                    <h6 className="user-location">[{listing.state}]</h6>
                }
                
                <p>
                    {listing.about && listing.about.length > 80 
                        ? `${listing.about.substring(0, 80)}...` 
                        : listing.about
                    }
                </p>
                {listing.serviceLocation && listing.serviceLocation !="undefined" && 
                    <p><strong>Service Location: </strong> {listing.serviceLocation}</p>
                }
                <strong>Categories: </strong>
                {listing.categories.map((category, index) => (
                    <p style={{display:"inline"}}>
                        {" "}{category}{index === listing.categories.length - 1 ? "" : ","}
                    </p>
                ))}
            </div>
            {/*
            <span className="material-symbols-outlined" onClick={handleDelete}>delete</span>
            */}
        </div>
        </Link>
    )
}
export default ListingDetails
