import {useState} from 'react'
import {useLogin} from '../hooks/useLogin'
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const {login, error, isLoading} = useLogin()
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const loginSuccess = await login(email, password);
            console.log("login success: ", loginSuccess);
    
            if (loginSuccess) {
                let preLoginUrl = sessionStorage.getItem('preLoginUrl');
                sessionStorage.removeItem('preLoginUrl');
                if (preLoginUrl === '/create-your-deathcare-directory-free-listing') {
                    preLoginUrl = '/listservice';
                }
                navigate(preLoginUrl || '/');
            }
        } catch (error) {
            console.error("Login failed:", error);
            // Handle login failure (e.g., show error message)
        }
    }
    

    return(
        <form className="login" onSubmit={handleSubmit}>
            <h3>Log in</h3>

            <label>Email: </label>
            <input 
                type="email" 
                onChange={(e)=> setEmail(e.target.value)}
                value={email}
               />
            <label>Password: </label>
            <input 
                type="password" 
                onChange={(e)=> setPassword(e.target.value)}
                value={password}
               />
            <button disabled={isLoading}>Log in</button>
            <div className="authLink">
                <a href="#" onClick={() => navigate('/signup')}>Don't have an account? Sign Up</a>
                <p> </p>
                <br></br>
                <a href="#" onClick={() => navigate('/forgot-password')}>Forgot Password?</a>
            </div>
            {error && <div className="error">{error}</div>}
        </form>
    )

}

export default Login