import React from 'react';
import '../styles/caregiverResources.css'

function CaregiverResources() {
    return (
        <div className="caregiver-resources">
            <h1 className="caregiver-resources-title">Caregiver Resources</h1>
            <p className="caregiver-resources-content">Caregiving is one of the most challenging and rewarding tasks we embark on as humans today. The world demands so much of us, and sometimes the ones we’re caring for do, too. Luckily, the end-of-life community is as wide as it is deep, and there are a lot of resources out there to help you or your loved ones improve death literacy, engage in advanced care planning, navigate loss, and honor rituals that support adaptive coping during grief.</p>
            <p className="caregiver-resources-content">We’ve captured just a few of them below, all of which are relevant to the U.S. and Canada. So whether you’re looking for information on aging well or a grief therapy forum, scroll on. (If there’s an organization you’d like to see listed, drop us a note.) We've also created additional resources to help you understand the role of a <a href="/doula-resources">death doula</a> as well as exploring what <a href="/green-burial-info">green burial</a> is.</p>
            <p className="caregiver-resources-content">We’d be totally remiss if we didn’t remind you that our directory is teeming with deathcare experts who can tailor solutions to your unique personal circumstances and state regulations. <a href="directory">Click here</a> to access our free directory and find the right practitioner for you.</p>
            
            <h2>Resources on Aging Well</h2>
            <p className="caregiver-resources-content">If you’re looking for resources on aging well, we have a few to recommend. Many of us know of an aging loved one or community member who is physically struggling with activities of daily life or facing a challenging financial situation due to limited monthly income. Helping them access the right information and support services to age well is a gift that cannot be overvalued.</p>

            <p className="caregiver-resources-content">And, don’t forget: if you’re willing to step in for elders in your community to help with basic errands or caregiver respite, be sure to register as one of our Good Neighbor Volunteers. <a href="create-your-deathcare-directory-free-listing">List Your Services</a></p>

            <h3>Podcasts on Aging</h3>
            <ul className="caregiver-resources-list">
                <li><a href="https://podcasts.apple.com/us/podcast/psychology-of-aging-with-dr-regina-koepp/id1504142987">Psychology of Aging with Dr. Regina Koepp</a></li>
                <li><a href="https://podcasts.apple.com/us/podcast/aging-well-podcast/id1602134189">Aging Well with Jeff Armstrong</a></li>
                <li><a href="https://podcasts.apple.com/us/podcast/the-peter-attia-drive/id1400828889">The Drive with Peter Attia, MD</a></li>
                <li><a href="https://podcasts.apple.com/us/podcast/fearless-aging/id1399112023">Fearless Aging</a></li>
                <li><a href="https://podcasts.apple.com/us/podcast/life-death-the-space-between/id1445726793">Life, Death, and the Space Between with Dr. Amy Robbins</a></li>
            </ul>

            <h3>Associations & Community on Aging</h3>
            <ul className="caregiver-resources-list">
                <li><a href="https://agewave.com/">Age Wave</a></li>
                <li><a href="https://www.aarp.org/">AARP</a></li>
                <li><a href="https://www.asaging.org/?gclid=CjwKCAjws9ipBhB1EiwAccEi1LpMSL8JzWD6r1BUVWtBucaZEzwdggWUcpA0zpaNKLli3SQCbi-XahoC8HYQAvD_BwE">American Society on Aging</a></li>
            </ul>

            <h3>Instagram Accounts on Aging</h3>
            <ul className="caregiver-resources-list">
                <li><a href="https://www.instagram.com/howtotrainyourdoctor/">How to Train Your Doctor with Matthew Tyler, MD</a></li>
                <li><a href="https://www.instagram.com/ageismisneverinstyle">Ageism is Never in Style</a></li>
                <li><a href="https://www.instagram.com/gloriousbroads">Glorious Broads</a></li>
                <li><a href="https://www.instagram.com/advancedstyle">Advanced Style</a></li>
                <li><a href="https://www.instagram.com/bluezones">Blue Zones</a></li>
                <li><a href="https://www.instagram.com/fourthousandmondays">Four Thousand Mondays with Jodi Wellman</a></li>
            </ul>

            <h2>Resources on Dying & Death</h2>
            <p className="caregiver-resources-content">Thankfully, much of the information on death and dying that became gatekept over the 19th century is experiencing an incredible revival and re-democratization. People everywhere are rediscovering how to support a dying friend’s wishes, including the widespread desire to die at home, and how to create meaningful, empowering rituals before and after death. Public forums on death, home funerals, and green burials are all making a resurgence across North America. Below are a few podcasts, organizations, and end-of-life Instagram accounts to help you navigate an upcoming or urgent death experience.</p>

            <h3>Podcasts on Death & Dying</h3>
            <ul className="caregiver-resources-list">
                <li><a href="https://eolupodcast.com/">End of Life University with Karen Wyatt, MD</a></li>
                <li><a href="https://bestlifebestdeath.com/podcast/">Best Life, Best Death with Diane Hullet</a></li>
                <li><a href="http://www.yg2d.com/thepodcast">You’re Going to Die with Ned Buskirk</a></li>
                <li><a href="https://whenyoudie.org/podcast/">When You Die</a></li>
                <li><a href="https://podcasts.apple.com/us/podcast/the-heart-of-hospice-podcast/id1139992713">The Heart of Hospice</a></li>
            </ul>

            <h3>Associations & Community Hubs about Death & Dying</h3>
            <ul className="caregiver-resources-list">
                <li><a href="https://deathcafe.com/">Death Cafe</a></li>
                <li><a href="https://www.deathoverdrafts.com/">Death Over Drafts</a></li>
                <li><a href="https://www.ekrfoundation.org/">Elisabeth Kübler-Ross Foundation</a></li>
                <li><a href="https://eolpc.org/">End of Life Psychedelic Care</a></li>
                <li><a href="https://www.homefuneralalliance.org/">National Home Funeral Alliance</a></li>
                <li><a href="https://www.greenburialcouncil.org/">Green Burial Council</a></li>
                <li><a href="https://endwellproject.org/">End Well</a></li>
                <li><a href="https://silentbookclub.substack.com/p/silent-book-club-of-death">Silent Book Club of Death (via Death Project Manager)</a></li>
            </ul>

            <h3>Instagram Accounts about Death & Dying</h3>
            <ul className="caregiver-resources-list">
                <li><a href="https://www.instagram.com/going_with_grace/">Going With Grace with Alua Arthur</a></li>
                <li><a href="https://www.instagram.com/hospicenursejulie">Hospice Nurse Julie</a></li>
                <li><a href="https://www.instagram.com/sacreddeathcare">Sacred Death Care with Dr. Sarah Kerr</a></li>
                <li><a href="https://www.instagram.com/gabrielle.elise.jimenez/">The Hospice Heart with Gabby Jimenez</a></li>
                <li><a href="https://www.instagram.com/suzannebobrienrn">Suzanne O’Brien of Doulagivers</a></li>
                <li><a href="https://www.instagram.com/deadgoodreading">Dead Good Reading</a></li>
                <li><a href="https://www.instagram.com/talkdeathdaily">Talk Death Daily</a></li>
                <li><a href="https://www.instagram.com/thedeathdeck">The Death Deck</a></li>
            </ul>

            <h2>Resources on Grief & Survivorhood</h2>
            <p className="caregiver-resources-content">When a person dies, there is a social ripple effect that happens with both seen and unseen consequences of mourning and bereavement. There is no one way to grieve and grief is most certainly not linear, so finding people and resources that you can count of for the journey are important. We hope that some of the groups and people in the list below can be a source of inspiration and connection on your healing journey.</p>

            <h3>Podcasts on Grief</h3>
            <ul className="caregiver-resources-list">
                <li><a href="https://podcasts.apple.com/us/podcast/good-mourning-grief-podcast/id1529978129">Good Mourning with Sallyjean Douglas and Imogen Carn</a></li>
                <li><a href="https://podcasts.apple.com/us/podcast/the-widowed-mom-podcast/id1468127632">The Widowed Mom with Krista St-Germain</a></li>
            </ul>

            <h3>Associations & Community Hubs about Grief Support</h3>
            <ul className="caregiver-resources-list">
                <li><a href="https://healgrief.org/">Healing Grief</a></li>
                <li><a href="https://www.dougy.org/">The Dougy Center for Grieving Children & Families</a></li>
                <li><a href="https://www.compassionatefriends.org/find-support/chapters/chapter-locator/?gclid=CjwKCAjws9ipBhB1EiwAccEi1Eye3OHwXlMnP3hlFCcPVMoT2NGR40rZCQyY3_Z9sc-bO1OK1_5BAhoC2esQAvD_BwE">The Compassionate Friends</a></li>
                <li><a href="https://nationalwidowers.org/">National Widowers Organization</a></li>
                <li><a href="https://www.aarp.org/caregiving/grief-loss-end-of-life/">AARP Grief and Loss Resources</a></li>
                <li><a href="https://copefoundation.org/">COPE Foundation</a></li>
            </ul>

            <h3>Instagram Accounts with Grief Resources</h3>
            <ul className="caregiver-resources-list">
                <li><a href="https://www.instagram.com/queergriefclub">Queer Grief Club with Jamie Thrower</a></li>
                <li><a href="https://www.instagram.com/hospicedoctorswidow">Hospice Doctor’s Widow with Jennifer O’Brien</a></li>
                <li><a href="https://www.instagram.com/motherwortandrose">Motherwort And Rose</a></li>
                <li><a href="https://www.instagram.com/help.texts">Help Texts</a></li>
                <li><a href="https://www.instagram.com/grieveleave">GrieveLeave</a></li>
            </ul>

            <p className="caregiver-resources-content">It’s widely reported that helping others can be a great source of healing, so if you’re in a place where volunteering as respite support, an errand runner, or lawn picker-upper might feel good, consider adding your name to our Good Neighbor registry. <a href="/create-your-deathcare-directory-free-listing">Click here</a> to List My Services landing page. You’re not signing up for guaranteed work, just guaranteed connection with others in your community.</p>
        </div>
    );
}

export default CaregiverResources;