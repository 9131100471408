import React from 'react';

class GoogleTag extends React.Component {
  componentDidMount() {
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-8XMEY6WHXJ";
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-8XMEY6WHXJ');
    `;
    document.head.appendChild(script2);
  }

  render() {
    return null;
  }
}

export default GoogleTag;
