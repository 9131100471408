import { format } from "date-fns";
import {useAuthContext} from "../hooks/useAuthContext"
import { FaThumbsUp } from 'react-icons/fa';
import { usePostContext } from "../hooks/usePostsContext"
import {Link} from "react-router-dom"
import { useNavigate } from 'react-router-dom';


const Post = ({ post }) => {
    const { user } = useAuthContext();
    const hasUserLiked = user && post.likes && post.likes.hasOwnProperty(user.userId);
    const { dispatch } = usePostContext();
    const likesMap = new Map(Object.entries(post.likes));
    const navigate = useNavigate();

    const handleLikeClick = async () => {
        console.log("user liked!");
        if(!user){
            navigate('/signup')
            return
        }
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/posts/${post._id}/like`, {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${user.token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ userId: user.userId }),
        });
        const updatedPost = await response.json();
        dispatch({ type: 'SET_POST', payload: { post: updatedPost } }); // Directly dispatch the object
    };

    return (
        <div className="forum-post">
            <div className="forum-texts">
                <Link to={`/post/${post._id}`}><h2 className="forum-title">{post.title}</h2></Link>
                <p className="forum-info">
                    <span className="forum-author">{post.username}</span>
                    <span className="forum-time"><time>{format(new Date(post.createdAt), 'MMM d, yyyy - HH:mm')}</time></span>
                    <span className="forum-comments">{post.comments ? post.comments.length : 0} comments</span>
                </p>
                <p className="forum-summary" dangerouslySetInnerHTML={{ 
                    __html: post.description.length > 200 
                            ? post.description.slice(0, 200) + '...' 
                            : post.description 
                    }}>
                </p>
                <button 
                    className={`like-button ${hasUserLiked ? 'liked' : ''}`}
                    onClick={handleLikeClick}
                >
                    <FaThumbsUp />
                </button>
                <span className="likes-count">{likesMap.size} likes</span>
            </div>
        </div>
    );
}

export default Post;
