import { useState } from "react";
import {useAuthContext} from './useAuthContext'
 
export const useSignup = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const {dispatch} = useAuthContext()

    const signup = async (username, email, password, contactable) => {
        setIsLoading(true);
        setError(null);
        console.log("Contactable in signup func: ", contactable);
    
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/user/signup', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({username, email, password, contactable})
            });
    
            const json = await response.json();
    
            if (!response.ok) {
                setError(json.error);
                setIsLoading(false);
                return false; // Signup failed
            }
    
            if (response.ok) {
                localStorage.setItem('user', JSON.stringify(json));
                dispatch({type: 'LOGIN', payload: json});
                setIsLoading(false);
                return true; // Signup successful
            }
        } catch (error) {
            setError("An error occurred during signup.");
            setIsLoading(false);
            return false; // Error occurred
        }
    };    

    return {signup, isLoading, error}
}