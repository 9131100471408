import React, { useState, useEffect } from 'react';
import Selector from "../components/Selector";
import { City, Country, State } from "country-state-city";
import AsyncSelect from "react-select/async"
import { useNavigate } from 'react-router-dom';
import '../styles/homepage.css';

const Home = () => {
    const [scrolled, setScrolled] = useState(false);
    let [categories, setCategories] =  useState('')
    let [skills, setSkills] =  useState('')
    let countryData = [Country.getCountryByCode('US'), Country.getCountryByCode('CA')];
    const [stateData, setStateData] = useState();
    const [cityData, setCityData] = useState();
    let [country, setCountry] = useState('');
    let [state, setState] = useState();
    let [city, setCity] = useState();
    let [serviceLocation, setServiceLocation] =  useState('')
    const navigate = useNavigate();
    const catOptions = [
      {value: "Death Doula or Midwife", label: "Death Doula or Midwife"},
      {value: "Green Burial Crafter", label:"Green Burial Crafter"},
      {value: "Advance Care Planning Specialist", label: "Advance Care Planning Specialist"},
      {value: "Legacy Crafter or Artisan", label: "Legacy Crafter or Artisan"},
      {value: "Guide for Psychedelic End of Life & Grief Processing", label: "Guide for Psychedelic End of Life & Grief Processing"},
      {value: "Herbalist", label: "Herbalist"},
      {value: "Good Neighbor Volunteer", label: "Good Neighbor Volunteer"},
  ]

  const catLoadOptions = (searchValue, callback) => {
      setTimeout(() => {
          const filteredOptions = catOptions.filter((option) => 
              option.label.toLowerCase().includes(searchValue.toLowerCase())
          );
          console.log("loadOptions", searchValue, filteredOptions);
          callback(filteredOptions)
      }, 2000) 
  }

  const handleCat = (e) => {
      setCategories(e);
      
  }

  const skillOptions = [
      {value: "Pet loss", label: "Pet loss"},
      {value: "Stillborn & Pediatric loss", label:"Stillborn & Pediatric loss"},
      {value: "Abortion", label: "Abortion"},
      {value: "Medical Aid in Dying (MAiD)", label: "Medical Aid in Dying (MAiD)"},
      {value: "Voluntary Stopping Eating & Drinking (VSED)", label: "Voluntary Stopping Eating & Drinking (VSED)"},
      {value: "Dementia", label: "Dementia"},
      {value: "Advance Care Planning", label: "Advance Care Planning"},
      {value: "Home funerals", label: "Home funerals"},
      {value: "Green burial", label: "Green burial"},
      {value: "Memorial & legacy projects", label: "Memorial & legacy projects"},
      {value: "Community outreach & public speaking", label: "Community outreach & public speaking"},
      {value:"Celebrant", label:"Celebrant"},
      {value:"Traumatic Death", label:"Traumatic Death"},
      {value:"Bedside Vigil", label:"Bedside Vigil"}
  ]

  const skillLoadOptions = (searchValue, callback) => {
      setTimeout(() => {
          const filteredOptions = skillOptions.filter((option) => 
              option.label.toLowerCase().includes(searchValue.toLowerCase())
          );
          console.log("loadOptions", searchValue, filteredOptions);
          callback(filteredOptions)
      }, 2000) 
    }

  const handleSkills = (e) => {

      setSkills(e);
        
  }

  const handleLoc = (e) => {

      setServiceLocation(e);
      console.log("service location: ", serviceLocation)
        
    }

    const locOptions = [
      {value: "Virtual", label: "Virtual"},
      {value: "In-Person", label:"In-Person"},
      {value: "Hybrid", label: "Hybrid"},
  ]

  const locLoadOptions = (searchValue, callback) => {
      setTimeout(() => {
          const filteredOptions = locOptions.filter((option) => 
              option.label.toLowerCase().includes(searchValue.toLowerCase())
          );
          console.log("loadOptions", searchValue, filteredOptions);
          callback(filteredOptions)
      }, 2000) 
  }

    useEffect(() => {
        setStateData(State.getStatesOfCountry(country?.isoCode));
      }, [country]);
    
    useEffect(() => {
        setCityData(City.getCitiesOfState(country?.isoCode, state?.isoCode));
      }, [state]);
    
    useEffect(() => {
        stateData && setState(stateData[0]);
      }, [stateData]);
    
    useEffect(() => {
        cityData && setCity(cityData[0]);
      }, [cityData]);

      
    useEffect(() => {
      const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > window.innerHeight) { // Checks if the scroll is beyond the height of the window (first viewport)
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      };
  
      // Add scroll event listener
      window.addEventListener('scroll', handleScroll);
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    const handleSubmit = async(e) => {
      e.preventDefault();

      // Construct the query string
      const queryParams = new URLSearchParams({
          category: categories?.value || '',
          skill: skills?.value || '',
          country: country?.name || '',
          state: state?.name || '',
          city: city?.name || '',
          serviceLocation: serviceLocation?.value || ''
      }).toString();
  
      // Redirect to the directory page with query parameters
      navigate(`/deathcare-directory?${queryParams}`);
  }
  
    return (
      <div className="home-page">
        <div className={`hero-section ${scrolled ? 'scrolled' : ''}`}>
        <h1>Your deathcaring community awaits.</h1>
          <p>Connecting you with next generation end-of-life practitioners & artisans</p>
        <form className="HsearchBar" onSubmit={handleSubmit}>
        <section>
          <label>I'm looking for a: </label>
          <AsyncSelect
          className='HSelects'
          loadOptions={catLoadOptions}
          value={categories}
          options={catOptions}
          defaultOptions
          onChange={(e) => handleCat(e)}
          styles={{
              control: (base) => ({
                ...base,
                borderColor: 'black',
                '&:hover': {
                  borderColor: 'black'
                }
              }),
              dropdownIndicator: (base) => ({
                ...base,
                color: 'white',
                backgroundColor: '#582c2c', 
                '&:hover': {
                  color: 'white', 
                  backgroundColor: '#582c2c', 
                }
              }),
              indicatorSeparator: () => ({
                display:'none'
              }),
            }}
          />

          {
            categories && categories.value === "Death Doula or Midwife" && (
              <>
                  <label>Specializing in: </label>
                  <AsyncSelect 
                      className='HSelects' 
                      loadOptions={skillLoadOptions} 
                      value={skills} 
                      options={skillOptions} 
                      defaultOptions 
                      onChange={(e)=>handleSkills(e) }
                      styles={{
                          control: (base) => ({
                            ...base,
                            borderColor: 'black',
                            '&:hover': {
                              borderColor: 'black'
                            }
                          }),
                          dropdownIndicator: (base) => ({
                            ...base,
                            color: 'white',
                            backgroundColor: '#582c2c', 
                            '&:hover': {
                              color: 'white', 
                              backgroundColor: '#582c2c', 
                            }
                          }),
                          indicatorSeparator: () => ({
                            display:'none'
                          }),
                        }}
                  />
              </>
            )
        }

            <div className="countryOptions">
                <div>
                <label>Located in:</label>
                <Selector className="country"  
                    data={countryData}
                    selected={country}
                    setSelected={setCountry}
                    placeholder="Select a country"
                />
                </div>
                {state && (
                <div>
                    <label>State :</label>
                    <Selector className = "state"
                    data={stateData}
                    selected={state}
                    setSelected={setState}
                    />
                </div>
                )}
                {city && (
                <div>
                    <label>City :</label>
                    <Selector className="city" data={cityData} selected={city} setSelected={setCity} />
                </div>
                )}
            </div>
            <button className="Hsearchbutton">Search</button>        
        </section>
        </form>
        </div>
        <div className="content-section">
          {/* Section 1 - Image Left, Text Right */}
          <div className="section section-1">
            <div className="image-container">
              <img src={require("../assets/homeMini1.png")} alt="" />
            </div>
            <div className="text-container">
              <h1>Learn & explore with a global community. </h1>
              <p><a href="/end-of-life-community-forum">Our forum</a> is meant to help you find solutions from other people who understand exactly what you’re going through -- and who care. </p>
            </div>
          </div>

          {/* Section 2 - Image Right, Text Left */}
          <div className="section section-2">
            <div className="text-container">
              <h1>Find the right providers for your values.</h1>
              <p>What matters to you at the end-of-life? Eco-friendliness? A positive legacy? A well designed plan for survivors? <a href="/deathcare-directory">We’ll connect you</a> with the experts who can make it happen.</p>
            </div>
            <div className="image-container">
              <img src={require("../assets/homeMini2.png")} alt="" />
            </div>
          </div>

          {/* Section 3 - Image Left, Text Right */}
          <div className="section section-3">
            <div className="image-container">
              <img src={require("../assets/homeMini3.png")} alt="" />
            </div>
            <div className="text-container">
              <h1>Open doors to new client opportunities.</h1>
              <p>We’re committed to growing the deathcaring movement by helping practitioners find clients, manage finances, and stay on top of market trends. </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default Home;

