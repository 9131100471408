import {useState, useEffect} from "react"
import { useParams } from "react-router-dom"
import { format } from "date-fns";
import {useAuthContext} from "../hooks/useAuthContext"
import { usePostContext } from "../hooks/usePostsContext"
import CommentSection from "../components/CommentSection";
import { useCommentsContext } from "../hooks/useCommentsContext";
import Comment from "../components/Comment";
import CommentForm from "../components/CommentForm";

export const PostPage = () => {
    const {postId} = useParams()
    
    const [post, setPost] = useState([]);

    const { user } = useAuthContext();

 
    
    useEffect(()=> {
        const handlePostPage = async () => {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/posts/' + postId)
                
            const json = await response.json()

            if(response.ok){
                setPost(json)
            }
            else{
                console.log("uh ohhhhh")
            }
            console.log("post: ", post)
        }
        handlePostPage();
    }, [postId])

    const {comments, dispatch} = useCommentsContext()
    useEffect(()=>{
        const fetchComments = async() => {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/posts/'+postId + "/comments")
            const json = await response.json()

            if (response.ok) {
                dispatch({type: 'SET_COMMENTS', payload:json})
            }
        }

        fetchComments()
    }, [dispatch])


    console.log("Comments: ", comments)
    return (
        <div className="post-page-container">
            <div className="post">
                <h2 className="iforum-title">{post.title}</h2>
                <p className="iforum-info">
                    <span className="iforum-author">{post.username}</span>
                    <span className="iforum-time">
                        <time>
                            {post.createdAt && !isNaN(new Date(post.createdAt).getTime()) 
                            ? format(new Date(post.createdAt), 'MMM d, yyyy') 
                            : 'Invalid Date'}
                        </time>
                    </span>
                </p>
                <p className="iforum-summary" dangerouslySetInnerHTML={{ __html: post.description }}></p>
            </div>
            
            <div className="iseparator"></div> {/* Separator between post and comments */}
            <div className ="comment-form">
                <CommentForm postId={postId}/>
            </div>
            <div className="icomments-section">
                {/* You can map over your comments array and render each comment inside a .comment div */}
                {comments && comments.map((comment) => (
                    <Comment key={comment._id} comment={comment} />
                ))}
                
            </div>
        </div>
    );
}