import { format } from "date-fns";
import {useAuthContext} from "../hooks/useAuthContext"
import { FaThumbsUp } from 'react-icons/fa';
import { useCommentsContext } from "../hooks/useCommentsContext";
import { useNavigate } from 'react-router-dom';
import CommentForm from "./CommentForm";
import { useRepliesContext } from "../hooks/useRepliesContext";
import { useState, useEffect } from 'react';
import Reply from "./Reply";

const Comment = ({ comment }) => {
    const { user } = useAuthContext();
    const hasUserLiked = user && comment.likes && comment.likes.hasOwnProperty(user.userId);
    const { dispatch:commentsDispatch } = useCommentsContext();
    const {replies, dispatch} = useRepliesContext();
    const likesMap = new Map(Object.entries(comment.likes));
    const navigate = useNavigate();
    const [showReplyForm, setShowReplyForm] = useState(false);
    const [showReplies, setShowReplies] = useState(false);
    const commentReplies = replies[comment._id] || [];


    const handleReplyClick = () => {
        setShowReplyForm(!showReplyForm);
    };

    const handleLikeClick = async () => {
        console.log("user liked!");
        if(!user){
            navigate('/signup')
            return
        }
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/posts/comments/${comment._id}/like`, {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${user.token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ userId: user.userId }),
        });
        const updatedComment = await response.json();
        console.log("updated comment", updatedComment)
        commentsDispatch({ type: 'SET_COMMENT', payload: { comment: updatedComment } }); // Directly dispatch the object
    };

    useEffect(() => {
        const fetchReplies = async () => {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/posts/comments/' + comment._id + "/replies");
            const json = await response.json();

            //console.log("jsson: ", json)

            if (response.ok) {
                dispatch({
                    type: 'SET_REPLIES',
                    commentId: comment._id,
                    payload: json
                });
            }
        };

        fetchReplies();
    }, [dispatch]);  // <-- Removed the dependency on repliesDispatch as it's no longer needed

    //console.log("replies: ", replies)
    //console.log("comment replies: ", commentReplies)
    return (
        <div className="icomment">
                <h3 className="comment-user">{comment.username}</h3>
                <p className="comment-text">
                    <span className="forum-time"><time>{format(new Date(comment.createdAt), 'MMM d, yyyy - HH:mm')}</time></span>
                    {/*<span className="comment-replies">0 replies</span>*/}
                </p>
                <p className="comment-text" dangerouslySetInnerHTML={{ __html: comment.text }}></p>
                <div className="interaction-section">
                    <div className="like-section">
                        <button 
                            className={`like-button ${hasUserLiked ? 'liked' : ''}`}
                            onClick={handleLikeClick}
                        >
                            <FaThumbsUp />
                        </button>
                        <span className="likes-count">{likesMap.size} likes</span>
                    </div>
                    <button className="reply-comment" onClick={() => setShowReplies(!showReplies)}>
                        {showReplies ? "Hide Replies" : "Show Replies"}
                    </button>
                    <button className="reply-comment" onClick={handleReplyClick}>Reply</button>
                </div>
                {
                    showReplies && commentReplies.length > 0 && (
                        <div className="replies-section">
                            {commentReplies.map(reply => (
                                <Reply key={reply._id} reply={reply} />
                            ))}
                        </div>
                    )
                }
                <div className="comment-form-section">
                    {showReplyForm && <CommentForm postId={comment.postId} commentId={comment._id}/>}
                </div>

                
        </div>
    );
}

export default Comment;