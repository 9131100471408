import React from 'react';
import '../styles/greenBurialInfo.css'

function CareerContent() {
  return (
    <div className="green-burial-info">
      <h1>Exploring End-of-Life Careers: A Pathway to Meaningful Professions</h1>
      <p>
        The landscape of career opportunities is ever-evolving, and for those seeking a path with profound purpose, the end-of-life space presents an array of fulfilling roles. In this realm, individuals can find themselves as compassionate companions, environmental artisans, or guides facilitating healing journeys. Many of these opportunities make for an ideal second or even third act for those who have already built careers in other fields.
      </p>
      <p>
        Let's embark on a journey through the diverse career prospects in this evolving field and address pertinent questions for those considering a career pivot to end-of-life care.
      </p>

      <h2>End-of-Life Career Paths</h2>
      <h3>Death Doulas</h3>
      <p>
        Often referred to as end-of-life companions, these professionals provide emotional, social, and spiritual support to individuals nearing the end of their lives and their families. Their guidance is not only helpful in managing the excess of end-of-life logistics in a values-centered way, but their presence also offers solace during a sensitive and significant period. <a href="/death-doula-careers">Click here</a> to learn more about the specifics of training to become a death doula.
      </p>

      <h3>Green Burial Managers and Artisans</h3>
      <p>
        With an increasing focus on sustainability, these roles cater to those advocating for eco-conscious end-of-life practices. For some, this calling manifests in crafting biodegradable burial options, such as shrouds or caskets, often using sustainably harvested materials. For others, land stewardship in the form of hybrid or green burial cemetery management most aligns with their prized principles of environmental stewardship. Don’t be fooled into thinking that either is more removed from the human side of end-of-life care as both paths typically interact regularly with decedents’ families.
      </p>

      <h3>Plant Healing Guides</h3>
      <p>
        Employing nature's therapeutic properties, these guides facilitate healing through plant-based remedies, offering comfort and support to individuals navigating the end-of-life journey. Such solutions can range from regionally-crafted herbal tonics to psychedelic plant access such as psilocybin. Given the resurgence of interest in both herbalism or psychedelics in recent years, those interested in this knowledge base have a growing plethora of training solutions available.
      </p>

      <h3>Legacy Writers</h3>
      <p>
        Capturing the essence of an individual's life story, legacy writers help craft narratives that immortalize memories, wisdom, and values, providing a lasting tribute to cherish. This path isn’t all about the pen though, but rather understanding how to ask the right thought-provoking questions and gently probe for the most authentic answers.
      </p>

      <h3>Memorial Artisans</h3>
      <p>
        Crafting personalized memorials and artifacts, memorial or legacy artisans create tangible tokens of remembrance, celebrating the lives of those who have passed. Memorial art may take many different forms from traditional media such as oil on canvas and videography to more innovative solutions such as three-dimensional castings and upcycled fabric arts.
      </p>

      <h2>Motivations and Considerations</h2>
      <h3>Why Choose End-of-Life Care?</h3>
      <p>
        The desire to make a meaningful difference in people's lives, provide comfort during challenging times, and foster a sense of peace in the transition from life to death often drive individuals toward careers in this field. Many deathcare workers have also experienced their own polarizing experiences around death and are eager to support others in accessing culturally competent, values-centered experiences at end-of-life.
      </p>

      <h3>End-of-Life Care Consideration</h3>
      <p>
        Working in end-of-life care demands emotional resilience and a deep sense of empathy. While immensely rewarding, constant exposure to grief and loss might affect one's emotional well-being and require actionably strategies for self-care and boundary setting. If you are someone who has difficulty setting boundaries or who easily becomes overwhelmed by others’ emotions, then end-of-life work – particularly roles that involve sitting bedside - may not be for you.
      </p>

      <h3>Understanding the Cons</h3>
      <p>
        End-of-life care can be emotionally taxing and intense. Some doulas also report that being at the forefront of the movement working to end death-related taboos can lead to some uncomfortable social situations. Logistically, most doulas operate as sole proprietors meaning that they’re responsible for all of the elements of running a successful business – from finding clients to receiving payment - in order to pay their bills. For this reason, we are seeing a slow rise in death doulas collaboratives as well as cooperative agreements with hospices and even legacy law firms.
      </p>
    </div>
  );
}

export default CareerContent;

