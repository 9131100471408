import { createContext, useReducer} from "react";

export const PostContext = createContext()

export const postReducer = (state, action) => {
    const now = new Date();
    let filtered = [];
    switch(action.type) {
        case 'SET_POSTS':
            return {
                posts: action.payload
            }
        case 'SET_POST':
            console.log(action.payload);
            const updatedPostId = action.payload.post._id;  // Adjusted this line
            console.log(updatedPostId)
            const updatedPosts = state.posts.map((post) => {
                if (post._id === updatedPostId) return action.payload.post;  // Adjusted this line
                return post;
            });
            return {
                ...state,
                posts: updatedPosts
            };
        case 'CREATE_POST':
            return{
                posts: [action.payload, ...(state.posts|| [])]
            }
        case 'DELETE_POST':
            return{
                posts: state.posts.filter((l)=>l._id !== action.payload._id)
            }
        case 'FILTER_POST':
            switch (action.filterType) {
                case 'Top today':
                    filtered = state.posts.filter(post => new Date(post.createdAt).toDateString() === now.toDateString()).sort((a, b) => new Map(Object.entries(b.likes)).size - new Map(Object.entries(a.likes)).size);
                    break;
                case 'Top this week':
                    const oneWeekAgo = new Date(now.setDate(now.getDate() - 7));
                    filtered = state.posts.filter(post => new Date(post.createdAt) >= oneWeekAgo).sort((a, b) => new Map(Object.entries(b.likes)).size - new Map(Object.entries(a.likes)).size);
                    break;
                case 'Top this month':
                    const oneMonthAgo = new Date(now.setMonth(now.getMonth() - 1));
                    filtered = state.posts.filter(post => new Date(post.createdAt) >= oneMonthAgo).sort((a, b) => new Map(Object.entries(b.likes)).size - new Map(Object.entries(a.likes)).size);
                    break;
                case 'Top this year':
                    const oneYearAgo = new Date(now.getTime());
                    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
                    filtered = state.posts.filter(post => new Date(post.createdAt) >= oneYearAgo).sort((a, b) => new Map(Object.entries(b.likes)).size - new Map(Object.entries(a.likes)).size);
                    console.log("filtered: ", filtered)
                    break;
                case 'Top all time':
                    filtered = [...state.posts].sort((a, b) => new Map(Object.entries(b.likes)).size - new Map(Object.entries(a.likes)).size);
                    console.log("filtered: ", filtered)
                    break;
                case 'New':
                    filtered = action.payload
                default:
                    filtered = [...state.posts].sort((a, b) => new Date(b.date) - new Date(a.date));
            }
            return {
                ...state,
                posts: filtered
            };

        case 'SEARCH_POSTS':
            const keywords = action.payload.toLowerCase().split(' '); // split search term into individual words
            filtered = state.posts.filter(post => 
                keywords.every(keyword => // use every to ensure all keywords are present
                    post.title.toLowerCase().includes(keyword) || 
                    post.description.toLowerCase().includes(keyword)
                )
            );
            return {
                ...state,
                posts: filtered
            };
            

        default:
            return state
    }
}

export const PostContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(postReducer, {
        posts: []
    })

    return(
        <PostContext.Provider value={{...state, dispatch}}>
            { children }
        </PostContext.Provider>
    )
}