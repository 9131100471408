import { useEffect, useState } from "react";
import {useAuthContext} from "../hooks/useAuthContext"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import { useNavigate } from 'react-router-dom';


const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['clean']
    ],
}

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link'
]

const CreatePost = () => {
    const {user} = useAuthContext();
    const navigate = useNavigate();
    const [emptyFields, setEmptyFields] = useState([])
    const [error, setError] =  useState(null)
    const [title, setTitle] = useState('')
    let [description, setDescription] = useState('')


    const createNewPost = async(e)=> {
        e.preventDefault()

        if(!user){
            setError('You must be logged in to create a post')
            return
        }
        if (!title || title.trim() === '') {
            setError('Title cannot be empty');
            return;
        }

        const post = {title, description, username:user.username}
        console.log("post: ", Object.keys(post))
        const formData = new FormData();
        Object.keys(post).forEach(key => {
            console.log("pikachu", key)
            formData.append(key, post[key]); // append all the properties of post object to formData
        });
        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/posts', {
            method: 'POST',
            body: formData,
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()

        if(!response.ok) {
            console.log("uh oh posts")
            setError(json.error)
            setEmptyFields(json.emptyFields)
        }

        if(response.ok){
            setError(null)
            setEmptyFields([])
            
            console.log('new post added')
            navigate('/end-of-life-community-forum')
            //dispatch({type: 'CREATE_LISTING', payload: json})
        }

    }
    

    return(
        <form onSubmit={createNewPost}>
            <input 
                type="title" 
                placeholder={'Title'} 
                value={title} 
                onChange={(e) => setTitle(e.target.value)}
                className="title-input" />
            <ReactQuill
                placeholder={'Description'}  
                value={description}
                onChange={newValue => setDescription(newValue)} 
                modules={modules} 
                formats={formats}/>
            <button className="create-post-btn">Create Post</button>
            {error && <div className="error">{error}</div>}
        </form>
        
    )
}

export default CreatePost