import { useEffect, useState } from "react";
import AsyncSelect from "react-select/async"
import Creatable, { useCreatable } from 'react-select/creatable';
import { City, Country, State } from "country-state-city";
import Selector from "./Selector";
import { useListingsContext } from "../hooks/useListingsContext";
import {useAuthContext} from "../hooks/useAuthContext"
import {Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useNavigate } from 'react-router-dom';
const StyledButton = styled(Button)({
  color: '#ffffff',
  backgroundColor: '#3f51b5',
  '&:hover': {
    backgroundColor: '#303f9f',
  },
});

const ListingForm = () => {
    const {dispatch} = useListingsContext();
    const {user} = useAuthContext();
    const [emptyFields, setEmptyFields] = useState([])
    let countryData = [Country.getCountryByCode('US'), Country.getCountryByCode('CA')];
    const [stateData, setStateData] = useState('');
    const [cityData, setCityData] = useState('');
    const [pfp, setPfp] =  useState('')
    const [name, setName] =  useState('')
    let [categories, setCategories] =  useState('')
    let [certifications, setCertifications] =  useState('')
    let [media, setMedia] =  useState([])
    let [languages, setLanguages] =  useState([])
    let [skills, setSkills] =  useState('')
    const [about, setAbout] =  useState('')
    let [country, setCountry] = useState('');
    let [state, setState] = useState('');
    let [city, setCity] = useState('');
    const [address, setAddress] =  useState('')
    let [serviceLocation, setServiceLocation] =  useState('')
    const [hours, setHours] =  useState({
      monday: { open: '', close: '' },
      tuesday: { open: '', close: '' },
      wednesday: { open: '', close: '' },
      thursday: { open: '', close: '' },
      friday: { open: '', close: '' },
      saturday: { open: '', close: '' },
      sunday: { open: '', close: '' }
    })
    const [existing, setExisting]= useState(false);
    const [exState, setExState]= useState(false);
    const [exCity, setExCity]= useState(false);
    const [pronouns, setPronouns] =  useState('')
    const [email, setEmail] =  useState('')
    const [phone_number, setPhone_Number] =  useState('')
    const [error, setError] =  useState(null)
    const [submission, setSubmission] = useState(null)
    const [viewable, setViewable] = useState(true) //necessary for form button update
    let viewing = true;
    
    const catOptions = [
      {value: "Death Doula or Midwife", label: "Death Doula or Midwife"},
      {value: "Green Burial Crafter", label:"Green Burial Crafter"},
      {value: "Advance Care Planning Specialist", label: "Advance Care Planning Specialist"},
      {value: "Legacy Crafter or Artisan", label: "Legacy Crafter or Artisan"},
      {value: "Guide for Psychedelic End of Life & Grief Processing", label: "Guide for Psychedelic End of Life & Grief Processing"},
      {value: "Herbalist", label: "Herbalist"},
      {value: "Good Neighbor Volunteer", label: "Good Neighbor Volunteer"},
    ]

    const skillOptions = [
      {value: "Pet loss", label: "Pet loss"},
      {value: "Stillborn & Pediatric loss", label:"Stillborn & Pediatric loss"},
      {value: "Abortion", label: "Abortion"},
      {value: "Medical Aid in Dying (MAiD)", label: "Medical Aid in Dying (MAiD)"},
      {value: "Voluntary Stopping Eating & Drinking (VSED)", label: "Voluntary Stopping Eating & Drinking (VSED)"},
      {value: "Dementia", label: "Dementia"},
      {value: "Advance Care Planning", label: "Advance Care Planning"},
      {value: "Home funerals", label: "Home funerals"},
      {value: "Green burial", label: "Green burial"},
      {value: "Memorial & legacy projects", label: "Memorial & legacy projects"},
      {value: "Community outreach & public speaking", label: "Community outreach & public speaking"},
      {value:"Celebrant", label:"Celebrant"},
      {value:"Traumatic Death", label:"Traumatic Death"},
      {value:"Bedside Vigil", label:"Bedside Vigil"}
    ]

    /*const langOptions = [{"value": "Afrikaans", "label": "Afrikaans"}, {"value": "Arabic", "label": "Arabic"}, {"value": "Bengali", "label": "Bengali"}, {"value": "Bulgarian", "label": "Bulgarian"}, {"value": "Catalan", "label": "Catalan"}, {"value": "Cantonese", "label": "Cantonese"}, {"value": "Croatian", "label": "Croatian"}, {"value": "Czech", "label": "Czech"}, {"value": "Danish", "label": "Danish"}, {"value": "Dutch", "label": "Dutch"}, {"value": "Lithuanian", "label": "Lithuanian"}, 
    {"value": "Malay", "label": "Malay"}, {"value": "Malayalam", "label": "Malayalam"}, {"value": "Panjabi", "label": "Panjabi"}, {"value": "Tamil", "label": "Tamil"}, {"value": "English", "label": "English"}, {"value": "Finnish", "label": "Finnish"}, {"value": "French", "label": "French"}, {"value": "German", "label": "German"}, {"value": "Greek", "label": "Greek"}, {"value": "Hebrew", "label": "Hebrew"}, {"value": "Hindi", "label": "Hindi"}, {"value": "Hungarian", "label": "Hungarian"}, 
    {"value": "Indonesian", "label": "Indonesian"}, {"value": "Italian", "label": "Italian"}, {"value": "Japanese", "label": "Japanese"}, {"value": "Javanese", "label": "Javanese"}, {"value": "Korean", "label": "Korean"}, {"value": "Norwegian", "label": "Norwegian"}, 
    {"value": "Polish", "label": "Polish"}, {"value": "Portuguese", "label": "Portuguese"}, {"value": "Romanian", "label": "Romanian"}, {"value": "Russian", "label": "Russian"}, {"value": "Serbian", "label": "Serbian"}, {"value": "Slovak", "label": "Slovak"}, {"value": "Slovene", "label": "Slovene"}, {"value": "Spanish", "label": "Spanish"}, {"value": "Swedish", "label": "Swedish"}, {"value": "Telugu", "label": "Telugu"}, {"value": "Thai", "label": "Thai"}, {"value": "Turkish", "label": "Turkish"}, 
    {"value": "Ukrainian", "label": "Ukrainian"}, {"value": "Vietnamese", "label": "Vietnamese"}, {"value": "Welsh", "label": "Welsh"}, {"value": "Sign language", "label": "Sign language"}, {"value": "Algerian", "label": "Algerian"}, {"value": "Aramaic", "label": "Aramaic"}, {"value": "Armenian", "label": "Armenian"}, {"value": "Berber", "label": "Berber"}, {"value": "Burmese", "label": "Burmese"}, {"value": "Bosnian", "label": "Bosnian"}, {"value": "Brazilian", "label": "Brazilian"}, 
     {"value": "Cypriot", "label": "Cypriot"}, {"value": "Corsica", "label": "Corsica"}, {"value": "Creole", "label": "Creole"}, {"value": "Scottish", "label": "Scottish"}, {"value": "Egyptian", "label": "Egyptian"}, {"value": "Esperanto", "label": "Esperanto"}, {"value": "Estonian", "label": "Estonian"}, {"value": "Finn", "label": "Finn"}, {"value": "Flemish", "label": "Flemish"}, {"value": "Georgian", "label": "Georgian"}, {"value": "Hawaiian", "label": "Hawaiian"}, 
    {"value": "Indonesian", "label": "Indonesian"}, {"value": "Inuit", "label": "Inuit"}, {"value": "Irish", "label": "Irish"}, {"value": "Icelandic", "label": "Icelandic"}, {"value": "Latin", "label": "Latin"}, {"value": "Mandarin", "label": "Mandarin"}, {"value": "Nepalese", "label": "Nepalese"}, 
    {"value": "Sanskrit", "label": "Sanskrit"}, {"value": "Tagalog", "label": "Tagalog"}, {"value": "Tahitian", "label": "Tahitian"}, {"value": "Tibetan", "label": "Tibetan"}, {"value": "Gypsy", "label": "Gypsy"}, {"value": "Wu", "label": "Wu"}]*/

    const langOptions = [{"value": "English", "label": "English"}, {"value": "Spanish", "label": "Spanish"},{"value": "Mandarin", "label": "Mandarin"},
    {"value": "Tagalog", "label": "Tagalog"},{"value": "Vietnamese", "label": "Vietnamese"},{"value": "French", "label": "French"},{"value": "Arabic", "label": "Arabic"}, 
    {"value": "Korean", "label": "Korean"}, {"value": "Russian", "label": "Russian"}, {"value": "German", "label": "German"},]

  const locOptions = [
    {value: "Virtual", label: "Virtual only"},
    {value: "In-Person", label:"In-Person only"},
    {value: "Hybrid", label: "Hybrid (Virtual and In-Person)"},
]
  const navigate = useNavigate();


    // Function to fetch user's listing
    const fetchUserListing = async () => {
        try {
            // Fetch the existing listing for the user from your backend
            const response = await fetch(process.env.REACT_APP_API_URL + `/api/listings?userEmail=${user.email}`);
            console.log("goo goo gaga: ", response)
            const existingListing = await response.json();
            console.log("existing list: ", existingListing)

            // Set the form fields with the existing listing details
            if (Array.isArray(existingListing) && existingListing.length > 0) {
              setExisting(true);
              const elist = existingListing[0];
              console.log("sanity check: ", existingListing[0].name)
              setName(elist.name);
              console.log("elist viewing: ", elist.viewing)
              setViewable(elist.viewing)

              const mappedCategories = elist.categories ? elist.categories.map(category => {
                const option = catOptions.find(opt => opt.value === category);
                return option || { value: category, label: category }; // return the matched object or create a new one
              }) : [];
              setCategories(mappedCategories);
              console.log("cats oo: ", categories)

              const mappedSkills = elist.skills ? elist.skills.map(skill => {
                const option = skillOptions.find(opt => opt.value === skill);
                return option || { value: skill, label: skill }; // return the matched object or create a new one
              }) : [];
              setSkills(mappedSkills);
              
              const certificationsArray = elist.certifications
              const certificationsObjects = certificationsArray.map(certification => ({
                value: certification,
                label: certification,
              }));
              setCertifications(certificationsObjects);

              const mediaArray = elist.media
              const mediaObjects = mediaArray.map(med => ({
                value: med,
                label: med,
              }));
              setMedia(mediaObjects);

              const mappedLanguages = elist.languages ? elist.languages.map(language => {
                const option = langOptions.find(opt => opt.value === language);
                return option || { value: language, label: language }; // return the matched object or create a new one
              }) : [];
              setLanguages(mappedLanguages);

              setAbout(elist.about);

              if(elist.country!=""){
                const countryNameToFilter = elist.country; // Change this to the country name you want to filter
                const filteredCountries = countryData.filter((country) => {
                  return country.name === countryNameToFilter;
                });
                console.log("countries: ", filteredCountries)
                setCountry(filteredCountries[0]);
                const countryCode = filteredCountries[0].isoCode;

                if(elist.state!="undefined" || elist.state!=undefined || elist.state!=''){
                  setExState(true);
                  const stateNameToFilter = elist.state.trim();
                  const statesOfCountry = State.getStatesOfCountry(countryCode);
                  const filteredStates = statesOfCountry.filter(state => state.name === stateNameToFilter);
                  const ccode = filteredCountries[0]['isoCode']
                  console.log(`State Name before trim: '${stateNameToFilter}' (Length: ${stateNameToFilter.length})`);
                  
                  if (filteredStates.length > 0) {
                    setExState(true)
                    setState(filteredStates[0]);
                    
                    const stateCode = filteredStates[0].isoCode;
                    //const cccode = filteredStates[0]['isoCode'];

                    if(elist.city && elist.city!="undefined"){
                      console.log("CITY: ", elist.city)
                      setExCity(true);
                      const cityNameToFilter = elist.city.trim();
                      const citiesOfState = City.getCitiesOfState(ccode, stateCode);
                      const filteredCities = citiesOfState.filter(city => city.name === cityNameToFilter);
                      if (filteredCities.length > 0) {
                          setCity(filteredCities[0]);
                      } 
                      else {
                          console.error("No matching city found in state");
                      }
                    }

                  }
                  else{
                    console.error("State.getAllStates() returned undefined");
                  }
                  
                }
              }
              setAddress(elist.address);
              console.log("sLocation: ", elist.serviceLocation)
              const simpleString = elist.serviceLocation; // this value would come from existingListing 
              const matchingObject = locOptions.find(option => option.value === simpleString);
              if (matchingObject) {
                setServiceLocation(matchingObject);
              }
              setHours(elist.hours);
              setPronouns(elist.pronouns);
              setEmail(elist.email);
              if(elist.phone_number!=null)
                setPhone_Number(elist.phone_number);
            }
            else{
              console.log("IN FALSE LAND")
              
              setViewable(false)
            }
            console.log("VIEIWING FINAL ELIST: ", viewable)
            console.log("name: ", name)
        } catch (error) {
            console.error('Error fetching user listing:', error);
        }
    };

    // Use the useEffect hook to fetch the existing listing for the user
    // when the component mounts
    useEffect(() => {
        fetchUserListing();
    }, [user]); // The effect will re-run if the user changes
    
    
    // Update State Data on Country Change
    useEffect(() => {
      if (country) {
        console.log("WE IN HERE")
        const newStates = State.getStatesOfCountry(country.isoCode);
        setStateData(newStates);

        // Reset state and city if existing state not found in new list
        if (!newStates.find(s => s.isoCode === state.isoCode)) {
          setState('');
          setCity('');
        }
      }
    }, [country, exState, state.isoCode]);

  // Update City Data on State Change
  useEffect(() => {
    if (state) {
      const newCities = City.getCitiesOfState(country.isoCode, state.isoCode) || [];
      setCityData(newCities);
  
      // Check if 'city' is not undefined/null and if the existing city exists in the new list
      if (city && !newCities.find(c => c.name === city.name)) {
        setCity(newCities.length > 0 ? newCities[0] : null);
      } else if (!city) {
        // If 'city' is undefined/null, reset it to the first city or null if there are no cities
        setCity(newCities.length > 0 ? newCities[0] : null);
      }
    }
  }, [state, country.isoCode]);

  /*
  useEffect(() => {
    if (country) {
        const newStates = State.getStatesOfCountry(country.isoCode);
        setStateData(newStates);
        setState(newStates[0]);  // Optionally reset state selection
    }
}, [country]);

  useEffect(() => {
      if (state) {
          const newCities = City.getCitiesOfState(country?.isoCode, state.isoCode);
          setCityData(newCities);
          setCity(newCities[0]);  // Optionally reset city selection
      }
  }, [state, country]);
  */


    //sets the state original
    useEffect(() => {
        console.log("State change: ", stateData)
        if(!exState){
          stateData && setState(stateData[0]);
          setExState(true)
        }
      }, [stateData]);
      
    //sets the city original
    useEffect(() => {
      if(!exCity){
        cityData && setCity(cityData[0]);
      }
    }, [cityData]);

    useEffect(() => {
      if (country) {
        const newStates = State.getStatesOfCountry(country.isoCode);
        setStateData(newStates);
        
        // Check if the existing state exists in the new list
        if (!newStates.find(s => s.isoCode === state.isoCode)) {
          setState(newStates.length > 0 ? newStates[0] : '');
          setCity(''); // Reset city as well
        }
      }
    }, [country]);

    useEffect(() => {
      if (state && country) {
        const newCities = City.getCitiesOfState(country.isoCode, state.isoCode) || [];
        setCityData(newCities);
    
        // Check if 'city' is not undefined/null and if the existing city exists in the new list
        if (city && !newCities.find(c => c.name === city.name)) {
          setCity(newCities.length > 0 ? newCities[0] : null);
        } else if (!city) {
          // If 'city' is undefined/null, reset it to the first city or null if there are no cities
          setCity(newCities.length > 0 ? newCities[0] : null);
        }
      }
    }, [state, country]);


    
   
    //console.log("exCity: ", exCity)
    


    const catLoadOptions = (searchValue, callback) => {
        setTimeout(() => {
            const filteredOptions = catOptions.filter((option) => 
                option.label.toLowerCase().includes(searchValue.toLowerCase())
            );
            console.log("loadOptions", searchValue, filteredOptions);
            callback(filteredOptions)
        }, 2000) 
    }

    const skillLoadOptions = (searchValue, callback) => {
      setTimeout(() => {
          const filteredOptions = skillOptions.filter((option) => 
              option.label.toLowerCase().includes(searchValue.toLowerCase())
          );
          console.log("loadOptions", searchValue, filteredOptions);
          callback(filteredOptions)
      }, 2000) 
    }

    const langLoadOptions = (searchValue, callback) => {
      setTimeout(() => {
          const filteredOptions = langOptions.filter((option) => 
              option.label.toLowerCase().includes(searchValue.toLowerCase())
          );
          console.log("loadOptions", searchValue, filteredOptions);
          callback(filteredOptions)
      }, 2000) 
    }

    const handleSkills = (e) => {

      setSkills(e);
        
    }

    const handleLanguages = (e) => {

      setLanguages(e);
        
    }


    const handleLoc = (e) => {

      setServiceLocation(e);
      console.log("service location: ", serviceLocation)
        
    }

    

  const locLoadOptions = (searchValue, callback) => {
      setTimeout(() => {
          const filteredOptions = locOptions.filter((option) => 
              option.label.toLowerCase().includes(searchValue.toLowerCase())
          );
          console.log("loadOptions", searchValue, filteredOptions);
          callback(filteredOptions)
      }, 2000) 
  }

  const handleCat = (e) => {
      console.log(Object.keys(e).length)

      //Might be inefficent way of storing categories, during submission scrap through keys and add all to categories array
      if(Object.keys(e).length >=1){
          console.log(e[Object.keys(e).length-1]["value"] );
          
      }
      setCategories(e);
      console.log(categories);
  }

    /*
    const ha = (e) => {
        setName(e.target.value);
        console.log(name);
    }
    */

    const handleTime = (event) => {
      const { name, value } = event.target;
      const [day, field] = name.split('.');
      setHours((prevHours) => ({
        ...prevHours,
        [day]: {
          ...prevHours[day],
          [field]: value
        }
      }));
    };

    
    const validFileTypes = ['image/jpg', 'image/jpeg', 'image/png']
    const handleImage = (e) => {
      if(e!=undefined){ 
        const file = e.target.files[0];
        console.log(file)

        if(!validFileTypes.find(type=> type===file.type)){
          setError("File must be in JPG/PNG format")
          return;
        }

        const form = new FormData();
        
        form.append('image', file)
      }
    }
    

    const handleSubmit = async(e, isSubmitting)=> {
      e.preventDefault()

      setSubmission(null)
      if(isSubmitting){
        console.log("VIEWING TRUE!")
      }
      else{
        console.log("VIEWING FALSE")
        viewing = false;
      }
      console.log("viewing: ", viewing)

      const formatSelectFields = (field) => {
        return field.map((f) => f.label);
      };

      if(!user){
        setError('You must be logged in to add a listing')
        return
      }

      if (!name || name.trim() === '') {
        setError('Name cannot be empty');
        return;
      }
    
      if (!Array.isArray(categories) || categories.length <= 0) {
        setError('Categories cannot be empty');
        return;
      }
      
      if (country!==""){
      country = country['name']
      console.log("country: ", country)
      if(state && state!=="" && state!==undefined){
        state = state['name']
      if(city && city!=="" && city!==undefined)
        city = city['name']
      }
      if(city==null){
        city=""
      }
      if(state==null){
        state=""
      }
    }
      console.log("Dragons")
      
      if(!Array.isArray(categories) || categories.length <= 0){
        console.log("cowabunga")
      }
      let temp = []
      console.log("categories: ", categories)
      for(let key in Object.keys(categories)){
        //console.log(categories[key])
        temp.push(categories[key].label)
      }
      categories = temp


      
      temp = []
      //console.log("categories: ", categories)
      if(Array.isArray(skills) && skills.length > 0 && Array.isArray(categories) && categories.includes('Death Doula or Midwife')){
        for(let key in Object.keys(skills)){
          temp.push(skills[key].label)
        }
      }
      skills = temp
      //console.log("skills: ", skills)
      
      //console.log("ee hooo:", certifications)
      temp = []
      if(certifications && certifications[0] !== null){
        //console.log("hmm monkey")
        for(let key in Object.keys(certifications)){
          temp.push(certifications[key].label)
        }
      }
        
      certifications=temp

      temp = []
      //media=[null]
      if(media && media[0] !== null){
        //console.log("hmm monkey")
        for(let key in Object.keys(media)){
          temp.push(media[key].label)
        }
      }
        
      media=temp

      temp = []
      //console.log("skills: ", skills)
      if(Array.isArray(languages) && languages.length > 0){
        for(let key in Object.keys(languages)){
          temp.push(languages[key].label)
        }
      }
      languages = temp
      console.log("languages: ", languages)
      
      

      temp = "";
      if(serviceLocation!=""){
        temp = serviceLocation.value;
        serviceLocation = temp;
      }

      const listing = {name, categories, certifications, about, country, state, city, address, hours, email, phone_number, pronouns, serviceLocation, skills, media, languages, viewing}
      const formData = new FormData();
      formData.append('image', pfp); // append the image file to formData
      formData.append('s3Email', user.email)
      
      Object.keys(listing).forEach(key => {
        if (Array.isArray(listing[key])) {
          // if the value is an array, convert it to a string using JSON.stringify
          formData.append(key, JSON.stringify(listing[key]));
        } else {
          formData.append(key, listing[key]); // append all the properties of listing object to formData
        }
      });
      /*
      for (var pair of formData.entries()) {
        console.log(pair[0]+ ', ' + pair[1]); 
      }
      */
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/listings', {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
      const json = await response.json()

      if(!response.ok) {
        console.log("uh oh")
        setError(json.error)
        setEmptyFields(json.emptyFields)
      }
      console.log("Json: ", json)
      if(response.ok){
        setSubmission('Listing succesfully created/updated')

        setPfp('')
        
        setError(null)
        setEmptyFields([])
        
        dispatch({type: 'CREATE_LISTING', payload: json})
        navigate('/listing/'+json._id);
      }
    }

    console.log("viewable: ", viewable)
    return(
        <form className="create" onSubmit={(e) => handleSubmit(e, true)}>
            {!existing ? (<h3>Add Your Own Listing</h3>):(<h3>Update Your Listing</h3>)}
            <h6>We’re so glad you’re joining us! To create and publish your practitioner profile for our directory, simply complete the fields below. Fields with an asterisk (*) are required. If you need to exit before your profile is complete, be sure to select “Save Draft” at the bottom of the page. When you are ready to publish, select the “Publish Listing. You can edit your profile at any time by logging in, clicking your username, and selecting “Listing.</h6>
            <label>*Full Name of Service Provider: </label>
            <input 
              type="text"
              onChange={(e) => setName(e.target.value)}
              value={name}
              placeholder="Name here..."
              className={`${emptyFields.includes('name') ? 'error' : ''} black-border-input`}
            />

            <label>*Select Provider Categories: </label>
            <AsyncSelect className='Selects' loadOptions={catLoadOptions} value={categories} options={catOptions} defaultOptions isMulti onChange={(e)=>handleCat(e) } 
            styles={{
              control: (base) => ({
                ...base,
                borderColor: 'black',
                '&:hover': {
                  borderColor: 'black'
                }
              }),
              dropdownIndicator: (base) => ({
                ...base,
                color: 'white',
                backgroundColor: '#582c2c', 
                '&:hover': {
                  color: 'white', 
                  backgroundColor: '#582c2c', 
                }
              }),
              indicatorSeparator: () => ({
                display:'none'
              }),
            }}/>
            
            {
                Array.isArray(categories) && categories.some(category => category.value === "Death Doula or Midwife" && category.label === "Death Doula or Midwife") && (
                    <>
                        <label>Select Skill/Specialization(s): </label>
                        <AsyncSelect 
                            className='Selects' 
                            loadOptions={skillLoadOptions} 
                            value={skills} 
                            options={skillOptions} 
                            defaultOptions 
                            isMulti
                            onChange={(e)=>handleSkills(e) }
                        />
                        <br />
                    </>
                )
            }

            {/* 
            <label>Select Skill/Specialization(s): </label>
            <AsyncSelect className='Selects' loadOptions={skillLoadOptions} value={skills} options={skillOptions} defaultOptions isMulti onChange={(e)=>handleSkills(e) }/>
            */}

            <label>Short Description (~250 Words): </label>
            <input
                className="black-border-input"
                type="text"
                onChange={(e) => setAbout(e.target.value)}
                value={about}
                placeholder="Description here..."
            />
            
            <label>Fill in Certifications: </label>
            <Creatable
              noOptionsMessage={() => "To add a certificate simply type the certification and press enter"}
              className='Selects' 
              dropdownIndicator 
              value={certifications} 
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              isMulti 
              onChange={(e)=>setCertifications(e)}
              placeholder='To add a certificate simply type the certification and press enter...'
              styles={{
                control: (base) => ({
                  ...base,
                  borderColor: 'black',
                  '&:hover': {
                    borderColor: 'black'
                  }
                }),
                dropdownIndicator: (base) => ({
                  ...base,
                  color: 'white',
                  backgroundColor: '#582c2c', 
                  '&:hover': {
                    color: 'white', 
                    backgroundColor: '#582c2c', 
                  }
                }),
                indicatorSeparator: () => ({
                  display:'none'
                }),
              }}
              />
            
            <label>How is the service offered: </label>
            <AsyncSelect className='Selects' loadOptions={locLoadOptions} value={serviceLocation} options={locOptions} defaultOptions onChange={(e)=>handleLoc(e) } 
            styles={{
              control: (base) => ({
                ...base,
                borderColor: 'black',
                '&:hover': {
                  borderColor: 'black'
                }
              }),
              dropdownIndicator: (base) => ({
                ...base,
                color: 'white',
                backgroundColor: '#582c2c', 
                '&:hover': {
                  color: 'white', 
                  backgroundColor: '#582c2c', 
                }
              }),
              indicatorSeparator: () => ({
                display:'none'
              }),
            }}/>
            
            <label>Relevant Media Links: </label>
            <Creatable className='Selects' value={media} isMulti onChange={(e)=>setMedia(e)} 
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            placeholder='To add a media link simply type the link and press enter...'
            styles={{
              control: (base) => ({
                ...base,
                borderColor: 'black',
                '&:hover': {
                  borderColor: 'black'
                }
              }),
              dropdownIndicator: (base) => ({
                ...base,
                color: 'white',
                backgroundColor: '#582c2c', 
                '&:hover': {
                  color: 'white', 
                  backgroundColor: '#582c2c', 
                }
              }),
              indicatorSeparator: () => ({
                display:'none'
              }),
            }}/>

            <label>Select Language(s): </label>
            <AsyncSelect className='Selects' loadOptions={langLoadOptions} value={languages} options={langOptions} defaultOptions isMulti onChange={(e)=>handleLanguages(e) } 
            styles={{
              control: (base) => ({
                ...base,
                borderColor: 'black',
                '&:hover': {
                  borderColor: 'black'
                }
              }),
              dropdownIndicator: (base) => ({
                ...base,
                color: 'white',
                backgroundColor: '#582c2c', 
                '&:hover': {
                  color: 'white', 
                  backgroundColor: '#582c2c', 
                }
              }),
              indicatorSeparator: () => ({
                display:'none'
              }),
            }}/>

            <label>Profile Picture: </label>
            <input
            accept="image/*"
            style={{ display: "none" }} // hides the default file input
            id="contained-button-file" // id used to connect the label (acting as a button) with this input
            type="file"
            onChange={e => setPfp(e.target.files[0])}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              component="span" // necessary to render the button as a span, which is valid as a child of a label
              startIcon={<CloudUploadIcon />}
              style={{margin: "1rem", backgroundColor: "#563138", color: "white"}}
            >
              Upload Profile Picture
            </Button>
          </label>
          {pfp && <Typography variant="body1">{pfp.name}</Typography>} 
              
            


            <h3>Contact Information</h3>
            <label>*Public Email Address: </label>
            <input
                className="black-border-input" 
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="example@domain.com..."
            />

            <label>Public Phone Number: </label>
            <input
                className="black-border-input" 
                type="text"
                onChange={(e) => setPhone_Number(e.target.value)}
                value={phone_number}
                placeholder="(XXX) XXX-XXXX..."
            />
            
          <section className="countrySection">
            <div>
              <h3 className="countryHeader">
                Location of Service
              </h3>
              <br />
              <div className="countryOptions">
                <div>
                  <p>Country :</p>
                  <Selector className="country"  
                    data={countryData}
                    selected={country}
                    setSelected={setCountry}
                  />
                </div>
                {state && (
                  <div>
                    <p>State :</p>
                    <Selector className = "state"
                      data={stateData}
                      selected={state}
                      setSelected={setState}
                    />
                  </div>
                )}
                {city && (
                  <div>
                    <p>City :</p>
                    <Selector className="city" data={cityData} selected={city} setSelected={setCity} />
                  </div>
                )}
              </div>
            </div>
          </section>

          <label>Address: </label>
            <input
                className="black-border-input"   
                type="text"
                onChange={(e) => setAddress(e.target.value)}
                value={address}
                placeholder="123 Example Road...."
            />
          
          
          
          {/*
         <h3>Hours of Operation</h3>
          <div>
            <label>Monday opening:</label>
            <input
              type="number"
              name="monday.open"
              value={hours.monday.open}
              onChange={handleTime}
            />
            <label>Monday closing: </label>
            <input
              type="number"
              name="monday.close"
              value={hours.monday.close}
              onChange={handleTime}
            />
          </div>
                */}
                
          
          <div className="submit-section">
            {!viewable ? (
                <>
                    <p className="opt-in-statement">By clicking ‘Publish Listing, ’ I understand that I am opting in to email communication from WeAreClio.com.</p>
                    <div className="buttons-container">
                        <button className="listingSubmission" type="submit">Publish Listing</button>
                        <button className="saveInProgress" onClick={(e) => handleSubmit(e, false)}>Save In Progress</button>
                    </div>
                </>
            ) : (
                <button className="listingSubmission" type="submit">Update Listing</button>
            )}
        </div>
          {/*
          <div className="submit-section">
              {!existing ? (<p className="opt-in-statement">By clicking ‘Add Listing, ’ I understand that I am opting in to email communication from WeAreClio.com.</p>
):(<div></div>)}
              {!existing ? (<button className="listingSubmission" type="submit">Add Listing</button>):(<button className="listingSubmission" type="submit">Update Listing</button>)}
              
          </div>
            */}
          
          {error && <div className="error">{error}</div>}
          {submission && <div className="success">{submission}</div>}
          
          
        </form>
        
         
    )
}

export default ListingForm