import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="terms-container">
            <h1>Terms & Conditions of Use</h1>
            
            <p>Welcome to weareclio.com (the "Site") owned and operated by We Are Clio, LLC. (the "Company," "us" or "we"). Please read these Terms and Conditions of Use (the "Agreement") carefully before using the Site. This Agreement sets forth the legally binding terms and conditions for your use of the Site, and your use of any other features, content, or applications offered from time to time in connection with the Site, excluding Third Party Content (defined below) not provided by us but accessible from the Site (collectively, the "Services"). By viewing or otherwise using the Site or Services, you agree to be bound by this Agreement. Use of the Site and Services are subject to acceptance without modification of (a) all of the terms and conditions contained in this Agreement, (b) all other operating rules, policies and procedures that may be published from time to time on the Site or through the Services and (c) all additional terms and conditions that may be promulgated by us from time to time with respect to certain Services offered by us on the Site, which are all incorporated into this Agreement by reference.</p>
            <p>The Site and Services are available only to individuals who are at least 18 years old. You represent and warrant that if you are an individual, you are at least 18 years old. We may, in our sole discretion, refuse to offer any portion of or all of the Site and/or Services to any person or entity or change his/her/its eligibility criteria at any time. This provision is void where prohibited by law and the right to access the Site and Services is revoked in such jurisdictions.
We reserve the right in our sole discretion to (A) change, modify, add, or delete portions of this Agreement, (B) change, suspend, limit or discontinue any portion of or all of the Site and/or Services (including, without limitation, the availability of any feature, database or content), and/or (C) charge for any portion of or all of the Site and/or Services, in each case, at any time. We will provide notice of such changes only by posting the updated Agreement on our Site and changing the "last updated" date listed above, by posting a notice on the Site or by sending you an email. This Agreement applies exclusively to your access to, interaction with, and use of, the Site and Services and does not alter in any way the terms or conditions of any other agreement you might have with us for certain products or services, or otherwise. We encourage you to review our Agreement each time you visit the Site or use a Service to see if it has been updated since your last visit. By clicking an “I Accept” button, or accessing or using the Site and/or the Services following a reposting of the changed Agreement or a posted notice from us or your receipt of an email from us notifying you of any changes to the Agreement constitutes acceptance of those changes.
</p>
            <p>If you have any questions regarding the use and features of the Site, please refer to the "Frequently Asked Questions" section of our Site. All other questions or comments about the Site should be directed to cliocustomerservices@gmail.com</p>

            <h2>Privacy Policy</h2>
            <p>We believe strongly in protecting user privacy. Users of the Site and/or the Services should refer to the <a href="https://www.joincake.com/privacy-policy/">Privacy Policy </a>and that is incorporated into this Agreement by this reference, before using the Site or the Services or providing any information to us.  Please note that the Privacy Policy does not apply to Public Submitted Items and such submission is covered below.</p>

            <h2>User Account</h2>
            <p>In order to access or use some features of the Sites, you will have to create a user account (a “User Account”). If you are under the age of 18, you are not permitted to register as a user or otherwise submit information to us. To create a User Account you must provide true, accurate and complete registration information (which shall be included in the definition of Private Submitted Items) and, if such information changes, promptly update the relevant registration information. During registration for a User Account, you will create a user name and password ("Account Information"), which may permit you access to certain areas of the Site not available to persons without a User Account. We reserve the right to refuse registration of, or cancel a User Account, in our sole discretion.
You are responsible for safeguarding and maintaining the confidentiality of your Account Information. You are solely responsible for the activity that occurs under your User Account and using your Account Information, whether or not you have authorized the activity. You agree to notify us immediately at cliocustomerservices@gmail.com of any breach of security or unauthorized use of your User Account or your Account Information.
</p>

            <h2>Access Grant; Proprietary Rights</h2>
            <p>Access Grant</p>
            <p>During the term of the Agreement and subject to the Agreement, we hereby grant you a non-exclusive, non-transferable, non-sublicensable, revocable right to access and use the Site and the Services for your personal, non-commercial, internal use only.  For avoidance of doubt, such right shall automatically terminate upon the termination of the Agreement.  You may not copy, rent, lease, lend, sell, redistribute, sublicense or assign the Site or the Services.
You agree that the access rights you have been granted prohibits you from using the Site or Services for any illegal or unauthorized purpose.  You will make every reasonable effort to prevent unauthorized third parties from accessing the Site or Services.  You represent and warrant that you will not breach the security of the Software (defined below) or the Services or attempt to gain unauthorized access to or interfere with operation of the Software or Services or with any other person’s use of the Software or Services. 
Additionally, you shall not: (a) take any action that imposes or may impose (as determined by us in our sole discretion) an unreasonable or disproportionately large load on our (or its third party providers') infrastructure; (b) interfere or attempt to interfere with the proper working of the Site and/or Services; (c) bypass any measures we may use to prevent or restrict access to any portion of the Site and/or Services (or other accounts, computer systems or networks connected to any of the Site and/or Services); or (d) use manual or automated software, devices, or other processes to "crawl" or "spider" any page of the Sites.
Furthermore, you shall not (directly or indirectly): (i) decipher, decompile, disassemble, reverse engineer or otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the Software, Site or Services, except to the limited extent applicable laws specifically prohibit such restriction, (ii) modify, translate, or otherwise create derivative works of any part of the Software, Site or Services, or (iii) copy, rent, lease, distribute, or otherwise transfer any or all of the rights that you receive hereunder. You shall abide by all applicable local, state, national and international laws and regulations.
</p>

            <h2>Content</h2>
            <p>All materials on the Site and available through the Services other than Private Submitted Items and Public Submitted Items (as defined below), including, without limitation, the Cake logo, design, text, graphics, other files, and the selection and arrangement thereof (“Our Content”) are the proprietary property of the Company or its affiliates or licensors. You may electronically copy and print to hard copy Our Content for the sole purpose of using materials on the Site for informational, non-commercial, personal and internal use only, provided you keep all copyright and other proprietary notices intact. Any other use of Our Content, including any commercial use or reproduction for purposes other than described above, or modification, distribution, republication, display, or performance of such materials, without our prior written consent is strictly prohibited.</p>

            <h2>Trademarks and Other Intellectual Property</h2>
            <p>"weareclio.com," "Cake," and other trademarks on the Site and available through the Services are trademarks or service marks of the Company or its affiliates or licensors, and may not be copied, imitated or used, in whole or in part, without the prior written consent of the Company. In addition, all page headers, custom graphics, button icons, and scripts are service marks, trademarks, and/or trade dress of the Company, and may not be copied, imitated, or used, in whole or in part, without the prior written permission of the Company.
The Company might have patents, trademarks, service marks, copyrights, or other intellectual property rights covering subject matter in the pages of the Site and any software underlying the Site and/or the Services (the “Software”). Our providing you with access to the Site or any Services does not give you any license to the Software or any of our other intellectual property. Any rights not expressly granted herein are reserved for us.
</p>
            <h2>Submissions</h2>
            <p>By submitting, disclosing, or offering any Public Submitted Item to us, either online or offline and whether or not solicited by us, including, without limitation, through your participation in a discussion forum or in an interactive area of the Site (but excluding all Private Submitted Items), you hereby grant and agree to grant to us an irrevocable, nonexclusive, perpetual, worldwide, royalty-free, fully paid-up, sublicensable and transferable right and license to use, display, perform, modify, reproduce, publish, distribute, make derivative works of, and otherwise commercially and non-commercially exploit your Public Submitted Items (and all copyright, trade secret, trademark, or other intellectual property rights therein), in any medium now existing or hereafter developed (including but not limited to print, film, or electronic storage devices), in connection with the Site, the Services and/or the Company's (and its successors' and assigns') business, including without limitation for promoting and redistributing part or all of the Site and the Services (and derivative works thereof) or the Services in any media formats and through any media channels (including, without limitation, third party websites), in each case, without compensation of any kind to you or any third party. You also hereby grant and agree to grant each user of the Site and/or the Services a non-exclusive right to access your Public Submitted Items through the Site and the Services and to use such Public Submitted Items as permitted through the functionality of the Site and the Services and under this Agreement.</p>
            <p>YOU HEREBY REPRESENT AND WARRANT THAT (A) YOU HAVE ALL NECESSARY RIGHT, POWER, AND AUTHORITY TO GRANT THE LICENSE SET FORTH HEREIN TO YOUR SUBMITTED ITEMS, AND (B) USE OF YOUR SUBMITTED ITEMS BY THE COMPANY AND WITH RESPECT TO PUBLIC SUBMITTED ITEMS, THE USERS OF THE SITE AND/OR SERVICES (INCLUDING, WITHOUT LIMITATION, PUBLISHING CONTENT ON OR AT THE SITE) WILL NOT VIOLATE, MISAPPROPRIATE, OR INFRINGE ANY COPYRIGHT, TRADE SECRET, TRADEMARK, PRIVACY, PUBLICITY, CONTRACT OR OTHER RIGHTS OF ANY THIRD PARTY. YOU WILL TAKE, AT YOUR OWN EXPENSE, ANY FURTHER ACTION (INCLUDING, WITHOUT LIMITATION, EXECUTION OF AFFIDAVITS AND OTHER DOCUMENTS) REASONABLY REQUESTED BY THE COMPANY TO EFFECT, PERFECT, AND CONFIRM THE LICENSE GRANTED TO THE COMPANY TO YOUR PUBLIC SUBMITTED ITEMS AS SET FORTH HEREIN.
</p>
            <p>Publication or use of any Public Submitted Items is at the sole discretion of the Company and the Company is under no obligation to publish or use any Public Submitted Item. If your Public Submitted Item is published and/or posted on the Site or otherwise used by the Company we may include your user name in conjunction with such publication, posting, or other use. By submitting, disclosing, or offering a Public Submitted Item, you hereby grant the Company the right to use your user name in connection with the publication, posting or other use of your Public Submitted Item. You must include your full name and e-mail address with your Submitted Item so we can contact you if we have any questions about your Public Submitted Item; however, only your user name will be published with your Public Submitted Item. The Company may include your name, likeness, photo, biographical information and/or other information in connection with the publication of your Public Submitted Item, if agreed upon by you.</p>
            <p>The Company does not endorse and has no control over any Submitted Items. The Company has the right, but not the obligation, to monitor the Site, Services and/or Submitted Items. The Company may remove any Submitted Items at any time for any reason (including, but not limited to, upon receipt of claims or allegations from third parties or authorities relating to such Submitted Items), or for no reason at all. Under no circumstances will the Company be liable in any way for any Submitted Items, including, but not limited to, any errors or omissions in any Submitted Items, or any loss or damage of any kind incurred in connection with use of or exposure to any Submitted Items posted, emailed, accessed, transmitted or otherwise made available via the Site and/or Services.</p>
            <h2>Release</h2>
            <p>In the event that you have a dispute with one or more users of the Site or Services, you release the Company (and its officers, directors, employees and agents) from claims, demands, and damages (actual and consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such disputes. If you are a California resident, you waive California Civil Code 1542, which says: "A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which if known by him must have materially affected his settlement with the debtor."</p>
            <h2>Disclaimer</h2>
            <p>THE SITES, SERVICES AND THE CONTENT AVAILABLE ON OR THROUGH THE SITE AND SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, AND YOU AGREE USE OF THE SITE, SERVICES AND CONTENT AVAILABLE ON OR THROUGH SUCH SITE AND SERVICES IS AT YOUR SOLE RISK. THE COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. THE COMPANY DOES NOT REPRESENT OR WARRANT CONTENT AVAILABLE ON OR THROUGH THE SITE AND/OR SERVICES ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR-FREE. THE COMPANY ALSO DOES NOT REPRESENT OR WARRANT THAT (A) THE SITE OR THE SERVICES WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, (B) ANY DEFECTS OR ERRORS WILL BE CORRECTED, OR (C) THE SITE OR SERVICES ARE FREE OF VIRUSES AND OTHER HARMFUL COMPONENTS. Some states do not allow the disclaimer of implied warranties, so the foregoing disclaimer may not apply to you.</p>
            <p>Reference to any products, services, processes, or other information, by trade name, trademark, manufacturer, and supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof by the Company.</p>
            <h2>Medical, Legal and Emergency Advice Disclaimer</h2>
            <p>We provide the Site and Services and permit the communication of information, comments, content and data using the Site and Services for informational purposes only.  Neither the Company nor any other person or entity contributing information to the Site or Services, including but not limited to our affiliates and our and their respective stockholders, members, directors, managers, officers, employees or agents, is providing any legal or medical advice or professional training on or through the Site or Services and the information should not be so construed or used. </p>
            <p>You represent and warrant that you fully understand and acknowledge that the Site and Services are:</p>
            <ul>
                <li>dependent upon a number of factors outside our control, including but not limited to, the operation of third party provided hardware, software and network services;
                </li>
                <li>
                not a substitute for professional legal and medical advice; and
                </li>
                <li>
                not expected to perform at the same level of performance and/or reliability one might expect from legal or medical services used in the delivery of critical legal advice or medical care environments.
                </li>
            </ul>
            <p>Cake is not a law firm and does not provide legal advice. Your use of the Cake-provided forms does not create an attorney-client relationship between you and Cake. Cake offers do-it-yourself online forms and generalized educational content about wills, which should not be considered, nor is it intended to be, legal advice.  If you need legal advice, please consult a lawyer. Any information you provide to Cake, and all communications between you and Cake, are not protected by attorney-client privilege.</p>
            <h2>Limitation of Liability</h2>
            <p>TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW, IN NO EVENT SHALL THE COMPANY OR ITS AFFILIATES OR LICENSORS (INCLUDING, WITHOUT LIMITATION, CONTENT PROVIDERS), OR ANY OF ITS OR THEIR OFFICERS, DIRECTORS, STOCKHOLDERS, CREDITORS, EMPLOYEES, AGENTS, REPRESENTATIVES, ATTORNEYS, OR SUPPLIERS BE LIABLE FOR ANY (A) SPECIAL, INCIDENTAL, INDIRECT, CONSEQUENTIAL, PUNITIVE OR RELIANCE DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOSS OF USE, LOSS OF PROFITS, LOSS OF DATA OR COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES OR (B) DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) $1.00, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE), OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE SITE OR THE SERVICES, INCLUDING, WITHOUT LIMITATION, USE OF, OR INABILITY TO USE, THE SITE, SERVICES OR CONTENT CONTAINED ON, OR ACCESSED THROUGH, THE SITE OR SERVICES, IN EACH CASE, EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.</p>
            <p>The Company is not responsible for any problems or technical malfunction of any telephone network or lines, computer online systems, servers, or providers, computer equipment, software, failure of any e-mail or redemption to be received by the Company on account of technical problems or traffic congestion online or on the Site or through the Services, or any combination thereof including any injury or damage to a person's computer related to or resulting from downloading any materials from the Site or Services. If, for any reason, the Site or Service is not capable of running online as planned, including infection by computer virus, bugs, tampering, unauthorized intervention, fraud, technical failures, or any other causes beyond the control of the Company that threatens or corrupts or adversely affects the administration, security, fairness, integrity or proper conduct of the Site or Services, we reserve the right, in our sole discretion, to cancel, terminate or suspend the use of the Site and Services. </p>
            <p>CAUTION: ANY ATTEMPT BY YOU TO DELIBERATELY DAMAGE THE SITE OR SERVICES OR UNDERMINE THE LEGITIMATE OPERATION OF THE SITE OR SERVICES MAY BE A VIOLATION OF CRIMINAL AND CIVIL LAWS AND SHOULD SUCH AN ATTEMPT BE MADE, WE WILL TERMINATE YOUR USE OF THE SITE AND SERVICES AND RESERVE THE RIGHT TO SEEK DAMAGES FROM YOU TO THE FULLEST EXTENT PERMITTED BY LAW.</p>
            <h2>Indemnification</h2>
            <p>You agree to defend, indemnify and hold harmless the Company, its affiliates, and all of its and their officers, directors, employees, stockholders, agents, suppliers, and licensors from and against any and all claims, damages, costs and expenses, including attorneys' fees, arising from or related to (a) your use of the Site and Services or any Materials or Submitted Items you provide, including, but not limited to, any claim by a third party that any Materials or Submitted Items you provide infringe or violate such third party's rights or interests and/or (b) your breach of this Agreement. We reserve the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will assist and cooperate with us in asserting any available defenses.</p>
            <h2>Removal and Disclosure</h2>
            <p>We reserve the right to, at our sole discretion, remove, take down, destroy or delete any Material and/or Submitted Items at any time and for any reason, including, but not limited to Material and Submitted Items that we deem inappropriate or which we believe might subject us to any liability. We may access, use and disclose transaction information about your use of the Site and Services, and any Material and Submitted Items transmitted by you via or in connection with the Site or Services, to the extent permitted by law, in order to comply with the law (e.g., a lawful subpoena), to initiate, render, bill and collect for our products and services, to protect our rights or property, or to protect users of the Site from fraudulent, abusive, or unlawful use of the Site and Services. INDIRECT OR ATTEMPTED VIOLATIONS OF THIS AGREEMENT OR ANY RELATED POLICY, GUIDELINE OR AGREEMENT, AND ACTUAL OR ATTEMPTED VIOLATIONS BY A THIRD PARTY ON YOUR BEHALF, SHALL BE CONSIDERED VIOLATIONS OF THIS AGREEMENT BY YOU.</p>
            <h2>Applicable Law</h2>
            <p>This Agreement shall be governed by and construed in accordance with the laws of the Commonwealth of Massachusetts, without regard to its choice of law principles to the contrary. The Uniform Computer Information Transactions Act will not apply to the Agreement.    Any then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes, who shall be selected from the appropriate list of JAMS arbitrators in accordance with the Arbitration Rules and Procedures of JAMS. The prevailing party in the arbitration shall be entitled to receive reimbursement of its reasonable expenses (including reasonable attorneys' fees, expert witness fees and all other expenses) incurred in connection therewith. Judgment upon the award so rendered may be entered in a court having jurisdiction or application may be made to such court for judicial acceptance of any award and an order of enforcement, as the case may be. Notwithstanding the foregoing, each party shall have the right to institute an action in a court of proper jurisdiction for injunctive or other equitable relief pending a final decision by the arbitrator. For all purposes of this Agreement, the parties consent to exclusive jurisdiction and venue in the state and federal courts residing in Boston, Massachusetts, and you hereby irrevocably and unconditionally consent and submit to the exclusive jurisdiction of such courts over any such suit, action, or proceeding arising out of this Agreement. Use of the Site and Services is not authorized in any jurisdiction that does not give effect to all provisions of the Agreement, including without limitation, this Section.</p>
            <p>You further agree that any claims or causes of action arising out of or related to the Agreement or the Site or Services, along with the content contained therein, shall be submitted to arbitration as provided below within one (1) year after such claim or cause of action arose, or such claim or cause of action shall be forever barred. </p>
            <h2>Termination</h2>
            <p>The Company reserves the right, without notice and in its sole discretion at any time, to terminate your right to use of the Site and Services, to block or prevent future access to and use of the Site and Services, and to remove and discard any Material and Submitted Items. If you wish to terminate your account, you may do so by going to Account Settings or contacting us at cliocustomerservices@gmail.com. Any fees paid for use of the Site or Services or for other products sold by us are non-refundable. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
            <h2>Claims of Copyright Infringement</h2>
            <p>We respect the intellectual property rights of others, and require that the people who use the Site and Services do the same. It is our policy to respond promptly to claims of intellectual property misuse. If you believe that your work has been copied and is accessible through the Site or Services in a way that constitutes copyright infringement, you may notify us by providing our copyright agent with the following information in writing:</p>
            <ol>
                <li>
                The electronic or physical signature of the owner of the copyright or the person authorized to act on the owner's behalf;
                </li>

                <li>
                Identification of the copyrighted work that you claim has been infringed;
                </li>

                <li>
                Identification of the material that is claimed to be infringing and information reasonably sufficient to permit us to locate the material;        
                </li>

                <li>
                Your name, address, telephone number, and email address;
                </li>

                <li>
                A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and
                </li>

                <li>
                A statement made under penalty of perjury that the above information in your notice is accurate and that you are the copyright owner or are authorized to act on the copyright owner's behalf.
                </li>
            </ol>
            <p>If we receive such a claim, we reserve the right to refuse or delete content and to terminate a user's Account.  Our designated agent to receive notification of claimed infringement under the Digital Millennium Copyright Act OF 1998 ("DMCA") is Sarah Parker Ward.  
            </p>      
            <p>After receiving a claim of infringement, the Company will process and investigate notices of alleged infringement and will take appropriate actions under the DMCA and other applicable intellectual property laws. Upon receipt of notices complying or substantially complying with the DMCA, we will act expeditiously to remove or disable access to any material claimed to be infringing or claimed to be the subject of infringing activity, and will act expeditiously to remove or disable access to any reference or link to material or activity that is claimed to be infringing. We will take reasonable steps promptly to notify the user that we have removed or disabled access to such material.</p>      
            <p>Upon receipt of a proper counter notification under the DMCA, the Company will promptly provide the person who provided the initial notification of claimed infringement with a copy of the counter notification and inform that person that we will replace the removed material or cease disabling access to it in 10 business days. Unless our designated agent first receives notice from the person who submitted the initial notification that such person has filed an action seeking a court order to restrain the user from engaging in infringing activity relating to the material on the Site or Services, we will replace the removed material and cease disabling access to it.</p>
            <p>You may provide us with a counter notification by providing our copyright agent the following information in writing:</p>
            
            <ol>
                <li>
                Your physical or electronic signature;
                </li>

                <li>
                Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled;
                </li>

                <li>
                A statement under penalty of perjury that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled; and     
                </li>

                <li>
                Your name, address, and telephone number, and a statement that you consent to the jurisdiction of Federal District Court for the judicial district in which your address is located, or if your address is outside of the United States, for any judicial district in which we may be found and that you will accept service of process from the person who provided the initial notification of infringement.
                </li>
            </ol>
            <p>In the event that any such claim relating to copyright infringement is asserted against the Company, such claim shall be resolved through binding arbitration in accordance with the Terms provided above.</p>
            <h2>Miscellaneous</h2>
            <p>If any provision of this Agreement shall be deemed unlawful, void, or for any reason unenforceable, then that provision shall be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect and enforceable.</p>
            <p>The Agreement is the entire agreement between you and the Company with respect to the use of the Site and Services, and supersedes all prior or contemporaneous communications and proposals (whether oral, written or electronic) between you and the Company with respect to the use of the Site and Services. All waivers must be in writing. The Company shall not be liable for any failure to deliver products or services or otherwise perform its obligations hereunder where such failure results from any cause beyond the Company's reasonable control. The Agreement is personal to you, and is not assignable, transferable or sublicensable by you except with the Company's prior written consent. The Company may assign, transfer or delegate any of its rights and obligations hereunder without consent. Any attempted transfer in violation hereof will be void and of no effect. This Agreement will be binding upon, and inure to the benefit of, the successors, representatives, and permitted assigns of the parties. No agency, partnership, joint venture, or employment relationship is created as a result of the Agreement and neither party has any authority of any kind to bind the other in any respect. In any action or proceeding to enforce rights under the Agreement, the prevailing party will be entitled to recover costs and attorneys' fees. Except as otherwise provided herein, all notices under the Agreement will be in writing and will be deemed to have been duly given when received, if personally delivered or sent by certified or registered mail, return receipt requested; when receipt is electronically confirmed, if transmitted by facsimile or e-mail; or the day after it is sent, if sent for next day delivery by recognized overnight delivery service.</p>
            <h2>Contact</h2>
            <p>If you have any questions or concerns, please contact us at cliocustomerservices@gmail.com</p>
        </div>
    );
}

export default TermsAndConditions;