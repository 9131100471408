import React, { useState } from "react";
import { Typography, Box, TextField, Container } from "@mui/material";
import { Autocomplete } from '@mui/lab';
import Paper from '@mui/material/Paper';

const Glossory = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');


  const glossary = {
    'Ablution': 'A religious task during which an individual’s body, in part of or in whole, must be cleansed. This may also apply to sacred or ceremonial objects.',
    'Active dying': 'The final phase of the dying process, typically lasting between a few hours and a few days. Symptoms of this phase of dying include but are not limited mottling of the skin, long pauses between breathing and irregular breathing patterns, a gurgling sound while breathing, and agitation that may include picking at self or linens.',
    'Active listening': 'A form of attentive, judgement-free listening in which the listener reflects statements back to the speaker to inspire further clarity, but withholds self-centering or providing advice.',
    'Activities of Daily Living (ADLs)': 'Basic self-care tasks that individuals typically perform daily, including eating, bathing, dressing, toileting, transferring (moving from bed to chair) and maintaining continence. The ability to complete ADLs is a common assessment tool toward the end-of-life to determine the most appropriate level of caregiving intervention.',
    'Advance directives': 'Documents, often legal in nature, that outline how a person would like to be cared for during the end of their life from a medical, spiritual, and social perspective.',
    'Aging in place': 'A term used to indicate aging in one’s own home',
    'Anatomical gift': 'A dying person’s intention to donate their corpse to science following their death.',
    'Anticipatory grief': 'The psychological discomfort associated with the loss of a loved one prior to their physical death.',
    'Aquamation': 'A water-based alternative to fire-based cremation that employs the process of alkaline hydrolysis to break down soft tissue. Skeletal remains are then crushed into fine dust. Requires significantly less energy than fire cremation and emits zero air emissions. Also referred to as resomation and water cremation. Legality varies by state.',
    'Ashes': 'A term often used to describe the fine bone dust remnants of cremation and aquamation',
    'Autonomy': 'The right of condition to self-govern; in the end of life space this often',
    'Bereavement': 'The period of grief that individuals experience after the death of someone – person or pet - they were close to. This natural response to loss can manifest in a wide variety of feelings that change in frequency and intensity over time.',
    'Bucket list': 'A euphemistic term used to describe a list of all the actions a person would like to accomplish prior to their death',
    'Burial at sea': 'The legal disposal of human remains, either in body or ash form, into a large body of water. In the U.S., this action is regulated by the Environmental Protection Agency.',
    'Burn-out': 'A state of emotional, physical, and mental exhaustion caused by excessive and prolonged exposure to stress; often results in lower overall performance as well as negative feelings toward self and others',
    'Caregiver': 'A person who is informally or formally responsible for another’s well being',
    'Casket': 'A casket is an ornate, decorative container used for both displaying and burying human remains; conventional caskets are typically made of steel, fiberglass, and other nonbiodegradable metals',
    'Casket spray': 'An ornamental floral bouquet used to decorate the casket',
    'Chosen family': 'Nonbiological kinship bonds deliberately chosen for mutual care and support, though not always legally acknowledged',
    'Coffin': 'Six- or eight-sided boxes used for burial or for transporting a body for cremation',
    'Celebration of life': 'A type of memorial, often secular in nature, which emphasizes the deceased’s individuality and the contributions made in their lifetime',
    'Columbarium': 'A physical location that holds individual cremains, ashes, or urns',
    'Comfort kit': 'A prescribed set of medications, including those for anxiety, pain, nausea, that are kept in a dying patients home to aid in a medical emergency, also referred to as a hospice kit',
    'Commingling': 'The act of burying together',
    'Coroner': 'Medical professional responsible for confirming, recording, and in some instances investigating death',
    'Creation stories': 'Cultural frameworks for understanding one’s role with the natural world, each other, and their ancestors over the life course which often includes the afterlife. Creation stories are found in many Indigenous cultures, often varying between tribes.',
    'Cremation': 'The reduction of human remains to ash form through the two-step process of applying high heat to the deceased’s body and subsequently crushing the bone fragments into fine dust',
    'Crematorium': 'A facility specially designed for cremating decedent’s bodies. Some such facilities also offer a final viewing room for the decedent’s family',
    'Cremains': 'The bone fragments and powder resembling ashes that remain after a body is cremated',
    'Cryogenics': 'A broad term of physics referring to the maintenance of materials at very low temperatures. Cryogenic preservation – or “cryopreservation” – is the storing of a deceased person’s body or head at low temperatures with the intent of scientific advancements allowing for re-animation',
    'Cyanosis': 'Bluish discoloration of the skin that appears as a result of sulfhemoglobin, or de-oxygenated blood cells',
    'Death café': 'A group discussion of death with no agenda, objective or themes. It is not a grief support or counseling session.',
    'Death doula': 'A contracted individual who provides nonmedical, values-aligned support to terminally ill individuals and their families; also referred to as a death midwife or end-of-life doula',
    'Death positive': 'A contemporary social movement attributed to Caitlin Dougherty that aims to overcome cultural taboos about death and invites greater transparency into the death planning process',
    'Death rattle': 'A colloquial term for the gurgling sound associated with respiratory secretions that are common during the active dying phase',
    'Decedent': 'The person who has died',
    'Do Not Resuscitate (DNR) order': 'A legally binding directive that indicates extreme life-saving interventions should not be enacted in order to keep a person alive.',
    'End-of-life plan': 'A compliment to a last will and testament, this plan speaks more to the emotional, psychological, and social expectations a person has for the end of their life.',
    'Entombment': 'A disposition method that involves placing the body in an above-ground structure such as a large tomb, crypt, or mausoleum. This option is often associated with certain cultural or religious traditions.',
    'Embalming': 'The voluntary process of removing blood and fluids from the dead body and inserting preservatives, surfactants, solvents, and coloration to slow decomposition and improve looks for a period of up to two weeks.',
    'Encoffiner': 'A person whose professional responsibility is to prepare the body of a deceased person and place it in the coffin, typically in a ceremonial manner',
    'Estate planning': "The term estate planning refers to the preparation of tasks that serve to manage an individual's financial situation in the event of their incapacitation or death",
    'Executor': 'The individual legally appointed to carry out a deceased person’s wishes as directed by their last will and testament. Tasks may include making funeral arrangements, accounting for and distributing all assets identified in the will, and settling disputes between claimants.',
    'Expressivism': 'The conceptualization of psychological healing to include stating aloud one’s emotions or perspectives, often to another individual',
    'Final disposition': 'The process or method by which a deceased person’s body is handled or cared for after death. It involves the various options available for the body’s ultimate resting place or treatment.',
    'Funeral': 'The ceremonies held for a decedent prior to burial or cremation; can be held in a home or a contracted setting such as a funeral home or church',
    'Goal-concurrent care': "A healthcare approach focused on providing interventions that are aligned with the patient’s preferences, goals and values, even when they are receiving curative or life-prolonging treatments. Goal-concurrent care requires open communication between healthcare providers, the patient, and their loved ones to ensure that care is tailored to meet the person's specific goals and values throughout their healthcare journey.",
    "Gravestone": "A physical marker, typically made of stone, that indicates where a decedent is buried",
    "Green burial": "The disposal of human remains in an environmentally-friendly format, typically utilizing an eco-friendly coffin and placing the grave at a shallower depth (3 feet) where decomposition will occur more rapidly.",
    "Grief": "Grief is the anguish experienced after significant loss, usually the death of a beloved person.",
    "Guided visualization": "A form of meditation in which a guide provides verbal cues to help a patient visualize a comfortable experience, often in a familiar or natural environment; often used to manage pain and reduce psychological stress for terminally ill individuals and their caregivers.",
    "Health Care Proxy (HCP)": "A legal document that appoints another individual (friend, family member, lawyer) as a surrogate to make healthcare decisions on a person’s behalf in the event they become incapacitated; sometimes referred to as medical power of attorney",
    "Herbalism": "A practice that involves using plants and their extracts for medicinal purposes. It is based on the belief that certain plants have healing properties that can promote health and well-being. In end-of-life care, herbalism can be used to provide comfort, support symptom management, and enhance the overall well-being of individuals.",
    "Home funeral": "The process of family and friends, next of kin, or a designated agent retaining custody of a decedent’s body between death and disposition (burial or cremation) instead of contracting such custody to a funeral home; sometimes referred to as home vigil or DIY funeral",
    "Home Health Aide": "A trained, certified healthcare worker who is contracted to provide in-home support such as bathing and dispensing medicine for an individual unable to complete activities of daily living",
    "Hospice": "A specific palliative care institution offering both medical and nonmedical support for terminally ill patients, most often through in-home care.",
    "Human composting": "Also referred to as the environmentally-friendly disposal of human remains at composting facilities which employ chemistry to naturally breakdown the body into functional soil, often in a matter of months; formally referred to as Natural Organic Reduction.",
    "Intensive Care Unit": "A hospital unit delineated for those who are critically ill and require nonstop observation with specialized interventions",
    "Interment": "Burial of a full body or cremated remains in a grave",
    "Keening": "A raw, intense form of mourning where individuals use their voices to convey their emotions and pay tribute to the person who has passed away; a practice that is typically culturally defined",
    "Last Will & Testament": "Often referred to simply as a will, this legal document expresses an individual’s final wishes to a probate court upon their death. Requirements vary by state.",
    "Legacy": "The concept of self a person hopes to leave behind in others’ memories",
    "Legacy writer": "A writer who supports others in the informal documentation process of their life’s story, either by actively interviewing and writing a life review narrative or by supporting the individual through thought-provoking questions and editorial support in their own composition of legacy letters.",
    "Life insurance": "Life insurance is a contract between a life insurance company and a policy owner",
    "Life review": "The practice of examining the life one has lived, often through chronological storytelling, while in dialogue with one or more people. In some cultures, this is part of a celebratory experience toward the end of one’s life.",
    "Medical Aid in Dying (MAiD)": "The activation of medical professionals to assist in hastening death by prescribing life-ending medications that patients then self-administer; currently only available in select states within the U.S.",
    "Medicare": "Federal health insurance designed for individuals aged 65 and older",
    "Memorial service": "A gathering of mourners at which the decedent’s body is not present",
    "Morbidity compression": "The concept that as scientific advancements extend life, they also work to minimize the deterioration of life so that people don’t just live longer, but live longer with an extended quality of life",
    "Mortuary": "A funeral home or morgue",
    "Mourning": "Some religions have specific mourning customs (e.g. sitting Shiva within the Jewish religion)",
    "Multi-morbidity": "Presence of two or more diseases in a patient at a given time; also referred to as co-morbidities; common at end-of-life",
    "Natural Organic Reduction": "Process in which decomposition of a corpse is hastened through contained management with the end product of soil; formal term for human composting",
    "Necropolitics": "A term coined by political theorist Achille Mbembe referring to the use of power and governance to control and dictate who lives and who dies. Necropolitics examines the ways in which political systems and structures exercise power through the control of life and death of certain groups of people, including through the perpetuation of violence.",
    "Notary": "An official authorized by the state to serve as an impartial witness to the signing of legal documents such as those involved in estate or advance care planning",
    "Patient advocate": "A professional role within hospital settings designed to assist patients and their families navigate their healthcare choices by distilling or simplifying information, answering questions, assisting in medical communication with doctors, and providing follow-up information as needed.",
    "Perseveration": "A common cognitive and behavioral tendency for Alzheimer’s and dementia patients in which they repeat the same words or phrases excessively, even without relevant context",
    "Physician Order for Life-Sustaining Treatment (POLST)": "State-directed document designed to assist in achieving goal-concurrent care for individuals with terminal illness who may often need hospitalization. This document involved three critical decisions, including the use of CPR. Referred to as a MOLST in some states.",
    "Obituary": "An article published in a newspaper notifying the public of a person’s death",
    "Organ donation": "The voluntary relinquishment of one’s organs upon death to be used for medical advancement or organ transplant. Often this requires that a person die in hospital and be swiftly taken to surgery upon death.",
    "Palliative care": "A field of healthcare focused on providing comfort, pain management, and emotional support to individuals with serious or terminal illnesses. It aims to improve the quality of life for the patient and their loved ones.",
    "Posthumous": "Meaning “after death;” often used when referring to publications or honors",
    "Postmortem": "Meaning “after death;” often used when referring to final wishes and disposition preferences",
    "Power of attorney": "Legal authorization given to someone to make healthcare and financial decisions on behalf of the terminally ill person if they become unable to do so themselves",
    "Psychedelic-Assisted Therapies (PAT)": "Novel treatments involving a combination of traditional talk-therapy and controlled use of psychedelics (e.g., psilocybin, ketamine, and MDMA) to assist in reducing anxiety and existential distress among those with chronic or life-limiting illnesses",
    "Psychopomp": "A human or spiritual being responsible for helping individuals transition from this life to a spiritual world. References to such individuals are found in historical and contemporary records around the world in a wide variety of forms.",
    "Pyre": "A wood-and-fire method for disposing of human remains that takes place in an open-air setting. Such a method may be selected for either ritualistic or religious reasons.",
    "Quality of life": "A person's perception of their position in life in the context of culture and values, including their degree of health, happiness, and ability to participate in meaningful activities",
    "Remembrance Portraits": "Photographs of a decedent and their loved ones; especially prominent an infant deaths to help parents grieve",
    "Respite care": "Temporary relief for caregivers by arranging for someone else to provide care, allowing them to take breaks and attend to their own needs",
    "Rigor mortis": "Stiffening of the body following death",
    "Ritual": "A spiritual framework for understanding and/or honoring a change through a series of intentional acts that more or may not include a social component",
    "Secular": "Not aligning with any specific religion",
    "Senescence": "The condition or process of deterioration with age",
    "Scattering garden": "A green space designed specifically for the disposal of cremains, or a cremated decedent’s ashes",
    "Sky burial": "A form of disposition in which remains were cut into and placed atop a mountainous outcropping or elevated platform where natural decomposition or scavenger consumption could occur. Most frequently associated with Tibetan sacred practice.",
    "Shroud": "A large piece of fabric used to tightly wrap the decedent’s body prior to burial or cremation. Often used for at-home funerals as well as green burials, shrouds are typically made of natural fabrics such as cotton, silk, or linen and can be sourced from home materials or purchased commercially.",
    "Shrouding board": "A board designed to securely carry a shrouded body to the grave that may or may not be buried with the body; also referred to as a trundle coffin or body board",
    "Stage theory of grief": "First established by Elisabeth Kubler-Ross, this theory indicated that grief occurred in a linear, timely fashion. This approach has largely given way to more individualized care.",
    "Stillborn": "A baby that dies in the womb after 20 weeks of pregnancy or during pregnancy; medically referred to as intrauterine fetal death",
    "Sudden Infant Death Syndrome": "The sudden and unexplained death of an infant before their first birthday",
    "Supplemental Advance Directive for Dementia (SADD)": "An addendum to an advance directive that specifies the type of care one wishes to receive should they lose their mental competence due to Alzheimer’s or other dementia-inducing disease",
    "Symbiocene": "A theoretical geological era characterized by harmonious interactions between humans and all other living beings. It suggests a vision of the future where humans actively cooperate with nature, recognizing our interconnectedness and striving for a sustainable and mutually beneficial relationship with the environment, even in disposition choices",
    "Telomeres": "A biological keystone that signals cellular aging",
    "Terminal illness": "An illness that cannot be cured and is likely to be the cause of death; also referred to as life-limiting illness",
    "Thanatology": "Field of study that examines what happens when someone dies, how people react to death, and how we can better understand and support those who are facing death or grieving the loss of a loved one. It encompasses a wide range of topics, including end-of-life care, grief and bereavement, funeral rituals, and the psychological and cultural aspects of death.",
    "Traumatic death": "A sudden or psychologically scarring death, often that occurred without prior medical indications, such as death by suicide, murder, or car accident.",
    "Urn": "A container meant to hold the cremains or “ashes” of the deceased. Urns are now available in a wide variety of materials and forms.",
    "Vanitas": "A genre of still life visual art that incorporates various symbolic objects intended to inspire contemplation of death and the futility of material goods among viewers",
    "Vigil": "A period of watchfulness, ritual, or prayer. This can be used to describe the period just before death or a memorial service after an individual has died.",
    "Voluntary Stopping Eating and Drinking (VSED)": "A passive form of hastening death in which an individual elects to stop eating and drinking and requests that care providers not support reflexive eating",
    "Wake": "A social gathering typically held before a funeral; historically this late-night community vigil took place in the decedent’s home and was designed to ensure that no evil spirits could take over the body prior to burial. In some cultures, the wake is as much a lively party in celebration of those who live on as an opportunity to say farewell to the deceased.",
    "Withdrawal of Care": "To cease certain medical interventions such as life support so that a person may die more quickly and peacefully; Note that this term, while widely used in healthcare, has negative connotations among both practitioners and patients.",
  };


  const handleSearchChange = (_, newValue) => {
    setSearchTerm(newValue || '');
    setValue(newValue);
  };

  const glossaryKeys = Object.keys(glossary);
    // Lowercase both the term and the search input to make search case-insensitive
  const filteredGlossaryKeys = glossaryKeys.filter((key) =>
    key.toLowerCase().includes(searchValue.toLowerCase())
  );

  const CustomPaper = (props) => {
    return <Paper {...props} style={{ maxHeight: '300px', overflow: 'auto' }} />
  };



  return (
    <div className="glossary-container">
      <Box style={{ padding: '20px', color: '#000', maxWidth: '800px', margin: '0 auto' }}>
        <Typography variant="h2" style={{ color: "#563138", marginBottom: "20px", textAlign: 'center' }}>
          End of Life Glossary
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "20px" }}>
          Death is complicated. It’s a medicalized, legal, social, and psychospiritual event. So when talking about,
          planning for, or experiencing a death, there can be a lot of jargon. Whether you’re an end-of-life
          practitioner or someone who has found themselves unexpectedly dealing with a death, this death glossary is
          a functional starting point to help you wade through it all. It can also be a tool for discovery. Skim or
          peruse, and take what you need.
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "20px" }}>
          We aim to keep this glossary up-to-date and inclusive. If you have a term you’d like to see included or a
          definition you suggest amending, <a href="/help">please message us!</a> This resource is intended to be an ever-changing,
          community effort.
        </Typography>


        <Autocomplete
          freeSolo
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            setSearchValue(newInputValue);
          }}
          options={glossaryKeys}
          onBlur={() => {
            setTimeout(() => setInputValue(''), 200);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#582c2c',
                  },
                  '&:hover fieldset': {
                    borderColor: '#582c2c',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#582c2c',
                  },
                },
                marginBottom: '20px',
              }}
              label="Search"
              variant="outlined"
              color="primary"
            />
          )}
          PaperComponent={CustomPaper}
          open={inputValue !== ''}
        />
        <Typography variant="h6" style={{ color: "#563138", marginTop: '20px' }}>
          Total terms visible: {filteredGlossaryKeys.length}
        </Typography>
        {filteredGlossaryKeys.map((key) => (
          <Box key={key} style={{ marginTop: '20px' }}>
            <Typography variant="h6" style={{ color: "#563138" }}>{key}</Typography>
            <Typography variant="body1" style={{ marginBottom: "20px" }}>{glossary[key]}</Typography>
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default Glossory;




