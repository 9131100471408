import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();
    const { token } = useParams(); 
    const buttonStyle = {
        backgroundColor: '#563138',
        color: 'white'
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }

        try {
            // Call API to reset password
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/user/reset-password', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ token, password })
            });

            if (response.ok) {
                alert('Password reset successfully');
                navigate('/login');
            } else {
                alert('Error resetting password');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error resetting password');
        }
    }

    return (
        <form className="reset-password-form" onSubmit={handleSubmit}>
            <h3>Reset Password</h3>
            <label>New Password:</label>
            <input 
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
            />
            <label>Confirm New Password:</label>
            <input 
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
            />
            <button type="submit" style={buttonStyle}>Reset Password</button>
        </form>
    );
}

export default ResetPassword;
