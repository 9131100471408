import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import '../styles/listingLanding.css';

const ListingLanding = () => {
    const navigate = useNavigate();
    const fadeInUp = {
        hidden: { y: 30, opacity: 0 },
        visible: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 1 } },
    };

    const handleAuthRedirect = (path) => {
        sessionStorage.setItem('preLoginUrl', window.location.pathname);
        navigate(path);
    };

    return (
        <motion.div className="landing-container" initial="hidden" animate="visible">

            <motion.h1 className="header-primary" variants={fadeInUp}>Welcome to Your New Client Connection Platform</motion.h1>

            <motion.p className="description" variants={fadeInUp}>
                Calling all death doulas, green burial crafters, legacy artisans, and other deathcare practitioners: Join our digital directory today and become part of a growing community committed to providing the highest quality of holistic support to individuals and families looking for a more empowered relationship with death.
            </motion.p>
            <motion.a href="#" onClick={() => handleAuthRedirect('/listservice')} className="btn-primary" variants={fadeInUp}>
                Create My Free Listing
            </motion.a>
            <div className="video-container">
            <iframe src="https://player.vimeo.com/video/899320697?h=c661db1664" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>

            
            

            {/* ... You can continue the same pattern for each section ... */}
            
            <motion.h2 className="header-secondary" variants={fadeInUp}>Make a Real Difference</motion.h2>
            <motion.p className="description" variants={fadeInUp}>
                We know from talking to hundreds of practitioners that supporting people at end-of-life is much more than a job; it’s a calling. We also know that independent practitioners often have a tough time finding the client sustainability necessary for them to keep doing what they’re meant to do without financial hardship.
            </motion.p>

            <motion.h2 className="header-secondary" variants={fadeInUp}>Expand Your Reach</motion.h2>
            <motion.p className="description" variants={fadeInUp}>
                Whether you’re just starting out or have been practicing for decades, we want your community to know you’re available for support. Clio helps do the work for you in terms of getting the word out. We track search trends, scholarship trends, media trends, and more to ensure that this movement is moving and that practitioners are accessible.
            </motion.p>

            <motion.h2 className="header-secondary" variants={fadeInUp}>Network with Others</motion.h2>
            <motion.p className="description" variants={fadeInUp}>
                As part of our community, you have the ability to post, reply, and interact on our community forum. Being called to this work doesn’t mean that it’s easy or that you’re innately gifted with all of the answer. Using our real-time resource allows you to quickly find inspiration from kindred spirits, solutions to business problems you’re facing, or simply crowd-source emotional support for especially challenging case work.
            </motion.p>

            <motion.a href="/listservice" className="btn-primary" variants={fadeInUp}>Join Us Today</motion.a>

            <motion.p className="description" variants={fadeInUp}>
                Creating your free listing takes about four minutes to complete, but can open a pathway to lasting impact for you and those you serve.
            </motion.p>

        </motion.div>
    );
}

export default ListingLanding;