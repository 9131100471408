import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <footer className="footer-container">
                <div className="footer-links">
                    <a href="/about">About</a>
                    <a href="/help">Help</a>
                    <a href="/terms-and-conditions">Terms & Conditions</a>
                </div>
                <div className="footer-disclaimer">
                    <p>We Are Clio does not employ any of the practitioners listed in our database and is not responsible for the conduct of users of our site. All information in member profiles is created by users of our site and neither generated nor verified by We Are Clio. We encourage you do your own diligence to ensure the caregiver you choose is appropriate for your needs and complies with applicable laws. Furthermore, information on WeAreClio.com should not be regarded as medical or legal advice and should be used for informational purposes only.</p>
                </div>
            </footer>
        </footer>
    );
}

export default Footer;