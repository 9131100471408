import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

function Selector({ data, selected, setSelected }) {
  const [query, setQuery] = useState("");
  // Ensure data is always an array
  const safeData = data || [];
  console.log("Data: ", safeData)
  const filteredPeople =  
    query === "" 
      ? safeData
      : safeData.filter((person) =>
          person.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <div className="iDivSelector"> 
      <Combobox value={selected} onChange={setSelected}>
        <div className="tiDivSelector">
          <div className="triDivSelector">
            <Combobox.Input
              className="oComboBox"
              displayValue={(person) => person.name}
              onChange={(event) => setQuery(event.target.value)}
              placeholder="Click on arrows for countries..."
            />
            <Combobox.Button className="oCBbutton">
              <ChevronUpDownIcon
                className="oChevronud"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="optionsComboBox">
              {filteredPeople.length === 0 && query !== "" ? (
                <div className="fourSelectorDiv">
                  Nothing found.
                </div>
              ) : (
                filteredPeople.map((person) => (
                  <Combobox.Option
                      key={person.id}
                      value={person}
                  >
                      {({ selected, active }) => (
                          <div className={`${
                                  active ? "ofPSelector active" : "ofPSelector"
                              }`}>
                              <span className={`OspanSelectorCB ${selected ? "selected" : ""}`}>
                                  {person.name}
                              </span>
                              {selected && (
                                  <span className={`TspanSelectorCB ${active ? "active" : ""}`}>
                                      <CheckIcon className="checkIconSelector" aria-hidden="true" />
                                  </span>
                              )}
                          </div>
                      )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}

export default Selector;
