import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(''); // New state for handling messages
    const [isError, setIsError] = useState(false); // New state to track if the message is an error
    const navigate = useNavigate();
    const buttonStyle = {
        backgroundColor: '#563138',
        color: 'white'
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage(''); // Reset message
        setIsError(false); // Reset error state

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/user/forgot-password', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ email })
            });
    
            if (response.ok) {
                setMessage('Email sent, check your email.'); // Set success message
            } else {
                setIsError(true); // Set error state to true
                const data = await response.json();
                setMessage(data.message || 'Error sending password reset email'); // Set error message from response or default message
            }
        } catch (error) {
            setIsError(true); // Set error state to true
            console.error('Error:', error);
            setMessage('An error occurred, please try again.'); // Set error message for catch block
        }
    }

    return (
        <form className="forgot-password" onSubmit={handleSubmit}>
            <h3>Forgot Password</h3>
            <label>Email: </label>
            <input 
                type="email" 
                onChange={(e) => setEmail(e.target.value)}
                value={email}
            />
            <button type="submit" style={buttonStyle}>Reset Password</button>
            {message && (
                <div className={isError ? 'error' : 'success'}>
                    {message}
                </div>
            )}
        </form>
    )
}

export default ForgotPassword;