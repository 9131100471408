import React, { useState } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import { useCommentsContext } from "../hooks/useCommentsContext";
import { useRepliesContext } from '../hooks/useRepliesContext';
const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['clean']
    ],
}

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link'
]

const CommentForm = ({ postId, commentId }) => {
    const {user} = useAuthContext();
    const [emptyFields, setEmptyFields] = useState([])
    const [error, setError] =  useState(null)
    const [text, setText] = useState('');
    const {comments, dispatch} = useCommentsContext()
    const {replies, dispatch:repDispatch} = useRepliesContext()


    const handleSubmit = async(e) => {
        e.preventDefault();

        if(!user){
            setError('You must be logged in to comment')
            return
        }

        if (!text || text.trim() === '') {
            setError('Comment cannot be empty');
            return;
        }
        console.log("commentId: ", String(commentId))
        let comment;
        if(!commentId){
            comment = {text, username:user.username, postId}
        }
        else{
            
            comment = {text, username:user.username, commentId}
        }
        console.log("comment: ", Object.keys(comment))
        const formData = new FormData();
        Object.keys(comment).forEach(key => {
            formData.append(key, comment[key]); 
        });
        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }
        

        let url = commentId ? process.env.REACT_APP_API_URL + '/api/posts/comments/'+commentId+'/reply': process.env.REACT_APP_API_URL + `/api/posts/${postId}/comments`;


       


        
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        });

        console.log(response)
        const json = await response.json()

        if(!response.ok) {
            console.log("uh oh comment not able to comment")
            setError(json.error)
            setEmptyFields(json.emptyFields)
        }

        if(response.ok){
            setError(null)
            setEmptyFields([])
            setText('')
            
            console.log('new comment/reply added')
            if(!commentId)
                dispatch({type: 'CREATE_COMMENT', payload: json})
            else
                repDispatch({type: 'CREATE_REPLY', payload: json})

        }

        
        
    };

    return (
        <form onSubmit={handleSubmit}>
            <ReactQuill
                placeholder={'Type a comment...'}  
                value={text}
                onChange={newValue => setText(newValue)} 
                modules={modules} 
                formats={formats}/>
            <button className="create-post-btn">Post Comment</button>
            {error && <div className="error">{error}</div>}            
        </form>
    );
};

export default CommentForm;