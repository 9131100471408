import { useEffect, useState} from "react"
import { useListingsContext } from "../hooks/useListingsContext"
import Selector from "../components/Selector";
import { City, Country, State } from "country-state-city";
import AsyncSelect from "react-select/async"
import React from "react";
import Creatable, { useCreatable } from 'react-select/creatable';
// components

import ListingDetails from '../components/ListingDetails'



const Directory = () => {
    const {listings, dispatch, showingOnlyVirtual} = useListingsContext()
    let [categories, setCategories] =  useState('')
    let [skills, setSkills] =  useState('')
    let countryData = [Country.getCountryByCode('US'), Country.getCountryByCode('CA')];
    const [stateData, setStateData] = useState();
    const [cityData, setCityData] = useState();
    let [country, setCountry] = useState('');
    let [state, setState] = useState();
    let [city, setCity] = useState();
    let [serviceLocation, setServiceLocation] =  useState('')
    const [emptyFields, setEmptyFields] = useState([])
    const [error, setError] =  useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const listingsPerPage = 5;


    const catOptions = [
        {value: "Death Doula or Midwife", label: "Death Doula or Midwife"},
        {value: "Green Burial Crafter", label:"Green Burial Crafter"},
        {value: "Advance Care Planning Specialist", label: "Advance Care Planning Specialist"},
        {value: "Legacy Crafter or Artisan", label: "Legacy Crafter or Artisan"},
        {value: "Guide for Psychedelic End of Life & Grief Processing", label: "Guide for Psychedelic End of Life & Grief Processing"},
        {value: "Herbalist", label: "Herbalist"},
        {value: "Good Neighbor Volunteer", label: "Good Neighbor Volunteer"},
    ]

    const catLoadOptions = (searchValue, callback) => {
        setTimeout(() => {
            const filteredOptions = catOptions.filter((option) => 
                option.label.toLowerCase().includes(searchValue.toLowerCase())
            );
            console.log("loadOptions", searchValue, filteredOptions);
            callback(filteredOptions)
        }, 2000) 
    }

    const handleCat = (e) => {
        setCategories(e);
        
    }

    const skillOptions = [
        {value: "Pet loss", label: "Pet loss"},
        {value: "Stillborn & Pediatric loss", label:"Stillborn & Pediatric loss"},
        {value: "Abortion", label: "Abortion"},
        {value: "Medical Aid in Dying (MAiD)", label: "Medical Aid in Dying (MAiD)"},
        {value: "Voluntary Stopping Eating & Drinking (VSED)", label: "Voluntary Stopping Eating & Drinking (VSED)"},
        {value: "Dementia", label: "Dementia"},
        {value: "Advance Care Planning", label: "Advance Care Planning"},
        {value: "Home funerals", label: "Home funerals"},
        {value: "Green burial", label: "Green burial"},
        {value: "Memorial & legacy projects", label: "Memorial & legacy projects"},
        {value: "Community outreach & public speaking", label: "Community outreach & public speaking"},
        {value:"Celebrant", label:"Celebrant"},
        {value:"Traumatic Death", label:"Traumatic Death"},
        {value:"Bedside Vigil", label:"Bedside Vigil"}
    ]

    const skillLoadOptions = (searchValue, callback) => {
        setTimeout(() => {
            const filteredOptions = skillOptions.filter((option) => 
                option.label.toLowerCase().includes(searchValue.toLowerCase())
            );
            console.log("loadOptions", searchValue, filteredOptions);
            callback(filteredOptions)
        }, 2000) 
      }

    const handleSkills = (e) => {

        setSkills(e);
          
    }

    const handleLoc = (e) => {

        setServiceLocation(e);
        console.log("service location: ", serviceLocation)
          
      }
  
      const locOptions = [
        {value: "Virtual", label: "Virtual"},
        {value: "In-Person", label:"In-Person"},
        {value: "Hybrid", label: "Hybrid"},
    ]
  
    const locLoadOptions = (searchValue, callback) => {
        setTimeout(() => {
            const filteredOptions = locOptions.filter((option) => 
                option.label.toLowerCase().includes(searchValue.toLowerCase())
            );
            console.log("loadOptions", searchValue, filteredOptions);
            callback(filteredOptions)
        }, 2000) 
    }

    useEffect(() => {
        setStateData(State.getStatesOfCountry(country?.isoCode));
      }, [country]);
    
    useEffect(() => {
        setCityData(City.getCitiesOfState(country?.isoCode, state?.isoCode));
      }, [state]);
    
    useEffect(() => {
        stateData && setState(stateData[0]);
      }, [stateData]);
    
    useEffect(() => {
        cityData && setCity(cityData[0]);
      }, [cityData]);
    
    const getCountryCodeByName = (countryName) => {
        const countries = Country.getAllCountries();
        const country = countries.find(c => c.name === countryName);
        return country ? country.isoCode : null;
    };
    

    
    const handleSubmit = async(e)=> {
        e.preventDefault()
        
        if (country!==""){
        country = country['name']
        console.log("country: ", country)
        if(state!=="" && state!==undefined){
          state = state['name']
        if(city!=="" && city!==undefined)
          city = city['name']
        }
        
      }
        
        let temp = categories["value"]
        categories = temp
        temp = skills["value"]
        skills = temp
        console.log("skills: ", skills)
        console.log("categories: ", categories)
        console.log("country: ", country)

        if(serviceLocation!==""){
            temp = serviceLocation.label
            serviceLocation = temp
        }
        
        
        

        if(categories!=undefined || country!=undefined || serviceLocation!=undefined){
            
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/listings')
            let json = await response.json()

            if (response.ok) {
                dispatch({type: 'SET_LISTINGS', payload:json})
            }
            
            console.log("state: ", state)
            if(state==undefined)
                state = ''
            if(city==undefined)
                city = ''
            if(serviceLocation==undefined)
                serviceLocation=''

            const userFilter = {
                category: categories,
                country:country,
                state: state,
                city: city,
                skill: skills,
                loc: serviceLocation
            }


            json = JSON.stringify(userFilter)
            console.log("before: ", json)
            dispatch({type:'FILTER_LISTING', payload:json})
        }
      }

    
    // Calculate the total number of pages
    const totalPages = Math.ceil(listings.length / listingsPerPage);

    // Function to update the current page
    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    // Get the listings for the current page
    const currentListings = listings.slice((currentPage - 1) * listingsPerPage, currentPage * listingsPerPage);
    useEffect(()=>{
        const params = new URLSearchParams(window.location.search);
        const countryName = params.get('country');
        const countryCode = getCountryCodeByName(countryName);
        const stateCode = params.get('state');
        const cityName = params.get('city');


        if (countryCode) {
            setCountry({ isoCode: countryCode, name: countryName });
            if (stateCode) {
                console.log("in state code")
                const states = State.getStatesOfCountry(countryCode);
                const state = states.find(s => s.isoCode === stateCode);
                setState(state);
            }

            if (cityName && stateCode) {
                const cities = City.getCitiesOfState(countryCode, stateCode);
                const city = cities.find(c => c.name === cityName);
                setCity(city);
            }
        }

        const categoryParam = params.get('category');
        if (categoryParam) {
            setCategories({ value: categoryParam, label: categoryParam });
        }
        const skillParam = params.get('skill');
        if(categoryParam == "Death Doula or Midwife"){
            if (skillParam) {
                setSkills({ value: skillParam, label: skillParam });
            }
            //console.log("pikachu")
        }
        
        

        const hasValidParams = ((countryName && stateCode && cityName) || categoryParam || skillParam);
        //setServiceLocation({ value: params.get('serviceLocation'), label: params.get('serviceLocation') });
        
        if(hasValidParams){
            //handleSubmit();
        }
        
        const fetchListings = async() => {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/listings')
            const json = await response.json()

            if (response.ok) {
                dispatch({type: 'SET_LISTINGS', payload:json})
            }
        }
        /* has to filter first instead of set */
        fetchListings()
        
        
    }, [dispatch])

    return(
        <div className="inDirectory">
            <form className="searchBar" onSubmit={handleSubmit}>
                <section>
                    <div>
                    <h3 className="searchHeader">
                        Find Providers Near You 
                    </h3>
                    <label>I'm looking for a: </label>
                    <AsyncSelect
                    className='Selects'
                    loadOptions={catLoadOptions}
                    value={categories}
                    options={catOptions}
                    defaultOptions
                    onChange={(e) => handleCat(e)}
                    styles={{
                        control: (base) => ({
                          ...base,
                          borderColor: 'black',
                          '&:hover': {
                            borderColor: 'black'
                          }
                        }),
                        dropdownIndicator: (base) => ({
                          ...base,
                          color: 'white',
                          backgroundColor: '#582c2c', 
                          '&:hover': {
                            color: 'white', 
                            backgroundColor: '#582c2c', 
                          }
                        }),
                        indicatorSeparator: () => ({
                          display:'none'
                        }),
                      }}
                    />

                    {
                        categories && categories.value === "Death Doula or Midwife" && (
                            <>
                                <label>Specializing in: </label>
                                <AsyncSelect 
                                    className='Selects' 
                                    loadOptions={skillLoadOptions} 
                                    value={skills} 
                                    options={skillOptions} 
                                    defaultOptions 
                                    onChange={(e)=>handleSkills(e) }
                                    styles={{
                                        control: (base) => ({
                                          ...base,
                                          borderColor: 'black',
                                          '&:hover': {
                                            borderColor: 'black'
                                          }
                                        }),
                                        dropdownIndicator: (base) => ({
                                          ...base,
                                          color: 'white',
                                          backgroundColor: '#582c2c', 
                                          '&:hover': {
                                            color: 'white', 
                                            backgroundColor: '#582c2c', 
                                          }
                                        }),
                                        indicatorSeparator: () => ({
                                          display:'none'
                                        }),
                                      }}
                                />
                                <br />
                            </>
                        )
                    }


                    <br />
                    <div className="countryOptions">
                        <div>
                        <label>Located in:</label>
                        <Selector className="country"  
                            data={countryData}
                            selected={country}
                            setSelected={setCountry}
                            placeholder="Select a country"
                        />
                        </div>
                        {state && (
                        <div>
                            <label>State :</label>
                            <Selector className = "state"
                            data={stateData}
                            selected={state}
                            setSelected={setState}
                            />
                        </div>
                        )}
                        {city && (
                        <div>
                            <label>City :</label>
                            <Selector className="city" data={cityData} selected={city} setSelected={setCity} />
                        </div>
                        )}
                    </div>
                    </div>
                </section>

                
                {/* 
                <label>With services offered: </label>
                <AsyncSelect className='Selects' loadOptions={locLoadOptions} value={serviceLocation} options={locOptions} defaultOptions onChange={(e)=>handleLoc(e) }/>
                */}
                <button>Search</button>
            </form>


            <div className = "directory">
            <div className="listings">
            {
                showingOnlyVirtual && currentListings && currentListings.length > 0 && (
                    <p style={{
                        backgroundColor: '#FFF0F5',
                        color: '#563138',
                        padding: '10px',
                        borderRadius: '8px',
                        border: '1px solid #563138',
                        textAlign: 'center',
                        margin: '20px 0',
                    }}>
                        No local listings in your area. Here are some virtual options instead:
                    </p>
                )
            }
                {currentListings && currentListings.length > 0 ? (
                    
                    currentListings.map((listing) => (
                        <ListingDetails key={listing._id} listing={listing} />
                    ))
                ) : (
                    <p>No Search Results, feel free to email <a href="mailto:services@weareclio.com">services@weareclio.com</a> for personalized help on finding the right service provider for you.</p>
                )}

                {/* Pagination Controls */}
                <div className="pagination-controls">
                    {/* Previous Page */}
                    {currentPage > 1 && <button onClick={() => goToPage(currentPage - 1)}>Prev</button>}

                    {/* Page Numbers */}
                    {Array.from({ length: totalPages }).map((_, index) => {
                        const pageNumber = index + 1;

                        // Always show the first page
                        if (pageNumber === 1) {
                            return (
                                <button
                                    key={index}
                                    onClick={() => goToPage(pageNumber)}
                                    className={currentPage === pageNumber ? 'active' : ''}
                                >
                                    {pageNumber}
                                </button>
                            );
                        }

                        // Show ellipsis and last page when current page is not near the last page
                        else if (totalPages > 5 && currentPage < totalPages - 2 && pageNumber === totalPages) {
                            return (
                                <React.Fragment key={index}>
                                    <span>...</span>
                                    <button
                                        onClick={() => goToPage(pageNumber)}
                                        className={currentPage === pageNumber ? 'active' : ''}
                                    >
                                        {pageNumber}
                                    </button>
                                </React.Fragment>
                            );
                        }

                        // When the current page is near the last page, show all remaining pages
                        else if (currentPage >= totalPages - 2 && pageNumber >= totalPages - 2) {
                            return (
                                <button
                                    key={index}
                                    onClick={() => goToPage(pageNumber)}
                                    className={currentPage === pageNumber ? 'active' : ''}
                                >
                                    {pageNumber}
                                </button>
                            );
                        }

                        // Show the immediate next pages
                        else if (pageNumber <= currentPage + 1 && pageNumber >= currentPage - 1) {
                            return (
                                <button
                                    key={index}
                                    onClick={() => goToPage(pageNumber)}
                                    className={currentPage === pageNumber ? 'active' : ''}
                                >
                                    {pageNumber}
                                </button>
                            );
                        }

                        // Don't render the button for other pages
                        return null;
                    })}

                    {/* Next Page */}
                    {currentPage < totalPages && <button onClick={() => goToPage(currentPage + 1)}>Next</button>}
                </div>
            </div>
            </div>
        </div>
    )
}

export default Directory