import {useEffect, useState} from "react"
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'
import '../styles/communityCalendar.css'
import EventPreview from '../components/EventPreview';
import { useEventsContext } from "../hooks/useEventsContext"
import { Link } from "react-router-dom";

const CommunityCalendar = ({ onDayClick }) => {
    const [value, onChange] = useState(new Date());
    const {events, dispatch} = useEventsContext()
    useEffect(()=>{
        const fetchEvents = async() => {
            const formattedDate = value.toISOString().split('T')[0];
            const url = `${process.env.REACT_APP_API_URL}/api/events?date=${formattedDate}`;
            console.log("url: ", url)
            const response = await fetch(url)
            const json = await response.json()
            
            if (response.ok) {
                dispatch({type: 'SET_EVENTS', payload:json})
            }
        }

        fetchEvents()
    }, [value, dispatch])
    const currentEvents = events;
    console.log("Events: ", events)


    return (
        <div className="eol-calendar">
            <div className="community-calendar">
            <Calendar
                onChange={onChange}
                value={value}
                onClickDay={onDayClick}
            />
            </div>
            <div className="create-event-section">
                    <Link to="/add-event" className="create-event-btn">Add Event</Link>
            </div>

            <div className="events">
                {currentEvents && currentEvents.length > 0 ? (
                    currentEvents.map((event) => (
                        <EventPreview key={event._id} event={event} />
                    ))
                ) : (
                    <p>No Current Events - maybe make one of your own :)</p>
                )}

                {/* Pagination Controls */}
            </div>
        </div>
    );
};

export default CommunityCalendar;