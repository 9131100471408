import { format } from "date-fns";
import {useAuthContext} from "../hooks/useAuthContext"
import { FaThumbsUp } from 'react-icons/fa';
import { useRepliesContext } from "../hooks/useRepliesContext";
import { useNavigate } from 'react-router-dom';


const Reply = ({ reply }) => {
    console.log("Reply inside: ", reply)
    const { user } = useAuthContext();
    const hasUserLiked = user && reply.likes && reply.likes.hasOwnProperty(user.userId);
    const { dispatch } = useRepliesContext();
    const likesMap = new Map(Object.entries(reply.likes));
    const navigate = useNavigate();

    const handleLikeClick = async () => {
        console.log("user liked reply!");
        if(!user){
            navigate('/signup')
            return
        }
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/posts/replies/${reply._id}/like`, {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${user.token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ userId: user.userId }),
        });
        const updatedReply = await response.json();
        console.log("updated reply", updatedReply)
        dispatch({ type: 'SET_REPLY', payload: { reply: updatedReply, commentId: reply.commentId  }}); // Directly dispatch the object
    };

    return (
        <div className="icomment">
                <h3 className="comment-user">{reply.username}</h3>
                <p className="comment-text">
                    <span className="forum-time"><time>{format(new Date(reply.createdAt), 'MMM d, yyyy - HH:mm')}</time></span>
                </p>
                <p className="comment-text" dangerouslySetInnerHTML={{ __html: reply.text }}></p>
                <button 
                    className={`like-button ${hasUserLiked ? 'liked' : ''}`}
                    onClick={handleLikeClick}
                >
                    <FaThumbsUp />
                </button>
                <span className="likes-count">{likesMap.size} likes</span>
        </div>
    );
}

export default Reply;