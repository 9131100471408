import {useState, useEffect} from "react"
import { useParams } from "react-router-dom"
import { useAuthContext } from "../hooks/useAuthContext"
import { useNavigate } from 'react-router-dom';

export const ListingPage = () => {
    const {listingId} = useParams()
    const {user} = useAuthContext()
    const [listing, setListing] = useState([]);
    const navigate = useNavigate();


  
    useEffect(()=> {
        const handleListingList = async () => {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/listings/' + listingId)
                
            const json = await response.json()

            if(response.ok){
                setListing(json)
            }
            else{
                console.log("uh ohhhhh")
            }
            console.log("listing: ", listing)
        }
        handleListingList();
    }, [listingId])
    
    const handleDelete = async(e) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/listings/'+listingId, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        })
        const json = await response.json()
        
        if(!response.ok) {
          console.log("Unable to delete")
          console.log("error: ", json.error)
        }
        else{
            navigate("/"); // This will navigate to /home
        }
      }

    //console.log("listing details: ", listing.certifications)
    //console.log("listing pfp: ", listing.pfp)
    console.log("LISTING CITY: ", listing.city)
    console.log("user: ", user)
    console.log("LISTING: ", listing)
    return(
        <div className="user-profile">
            
            {user && user.userId && listing.user_id === user.userId && (
                <div>
                    <div className="delete-listing-container">
                        <button
                            className="listingDeletion"
                            onClick={() => {
                                if (window.confirm("Are you sure you want to delete your listing? This action is irreversible.")) {
                                    handleDelete()
                                }
                            }}
                        >
                            Delete Listing
                        </button>
                    </div>
                    <div className="edit-button-container">
                        <a href="/listservice" className="edit-button">Edit</a>
                    </div>
                </div>
            )}

            <div className={`profile-content ${user && user.userId && listing.user_id === user.userId ? "with-edit-button" : ""}`}>
            <div className="user-details">
                {listing.pfp ? (<img src={listing.pfp} alt="user profile"/>):<></>}
                <div className="user-text-details">
                    <h1 className="user-name"><b>{listing.name}</b></h1>
                    <h5 className="user-email">
                        {listing.email}
                        {listing.email && listing.phone_number ? ' | ' : ''}
                        {listing.phone_number}
                    </h5>                    
                    <h4 className="user-location">
                        {listing.city !== "undefined" && listing.city && listing.state !== "undefined" && listing.state && listing.country !== "undefined" && (
                            <span>[{listing.city}, {listing.state}]</span>
                        )}

                        {(!listing.city || listing.city=="undefined") && listing.state && listing.state !== "undefined" && listing.country !== "undefined" && (
                            <span>[{listing.state}]</span>
                        )}

                        {((listing.city !== "undefined" && listing.city) || (listing.state !== "undefined" && listing.state)) && listing.country !== "undefined"  && listing.country && listing.serviceLocation && (
                            <span> | </span>
                        )}

                        {listing.serviceLocation && listing.serviceLocation!=="undefined" && (
                            <span>{listing.serviceLocation}</span>
                        )}
                    </h4>
                </div>
            </div>
        {listing.about!="" ? (
            <h3 className="user-about"><b>About:</b> {listing.about}</h3>
        ): (<h3 className="user-about">{listing.about}</h3>)}


            {listing.skills != Array(0) && listing.skills != undefined && listing.skills[0] != ''  && listing.skills.length!=0? (
                <div className="user-skills">
                <span>Skill/Specialization(s): </span>
                {listing.skills && listing.skills.map((skill, index) => (
                    <span className="skill-tag">
                        {skill}
                    </span>
                    ))}
                </div>
            ): (<div></div>)}
            
            {listing.certifications != Array(0) && listing.certifications != undefined && listing.certifications[0] != ''  && listing.certifications.length!=0? (
                <div className="user-certifications">
                <span>Certifications: </span>
                {listing.certifications && listing.certifications.map((certification, index) => (
                    <span className="certification-tag">
                        {certification}
                    </span>
                    ))}
                </div>
            ): (<div></div>)}

            {listing.languages != Array(0) && listing.languages != undefined && listing.languages[0] != ''  && listing.languages.length!=0? (
                <div className="user-languages">
                <span>Languages: </span>
                {listing.languages && listing.languages.map((language, index) => (
                    <span className="language-tag">
                        {language}
                    </span>
                    ))}
                </div>
            ): (<div></div>)}

            {listing.media && listing.media.length > 0 && listing.media[0] !== '' ? (
                <div className="user-media">
                    <span>Media: </span>
                    {listing.media.map((med, index) => {
                        if (med) {
                            // Ensure the URL starts with 'http://' or 'https://'
                            const formattedURL = med.startsWith('http://') || med.startsWith('https://') ? med : 'http://' + med;
                            return (
                                <a href={formattedURL} target="_blank" rel="noopener noreferrer" className="media-link">
                                    {med}
                                </a>
                            );
                        }
                        return null;
                    })}
                </div>
            ) : (<div></div>)}


            
            <div className="user-categories">
                {listing.categories && listing.categories.map((category, index) => (
                <span className="category-tag">
                    {category}
                </span>
                ))}
            </div>
            
            
            </div>    
        </div>
    )
}