import React, { useState } from 'react';
import { useEventsContext } from "../hooks/useEventsContext";
import { Link } from "react-router-dom";
import '../styles/EventPreview.css';

const EventPreview = ({ event }) => {
    const { dispatch } = useEventsContext();
    const [showDetails, setShowDetails] = useState(false);

    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };

    return (
        <div className={`event-preview ${showDetails ? 'open' : ''}`}>
            <div className="event-basic-info">
                <h3>{event.title}</h3>
                <p>{new Date(event.startDate).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })} to {new Date(event.endDate).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}</p>
                <button onClick={toggleDetails} className="details-toggle-btn">{showDetails ? '-' : '+'}</button>
            </div>
            
            {showDetails && (
                <div className="event-additional-info">
                    {event.link && <Link to={event.link}>Link: {event.link}</Link>}
                    {event.description && <p>Description: {event.description}</p>}
                    {event.username && <p>Submitted by: {event.username}</p>}
                </div>
            )}
        </div>
    );
};

export default EventPreview;
