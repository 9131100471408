import { useRef, useState, useEffect} from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../styles/nav.css";
import {useLogout} from '../hooks/useLogout'
import { useAuthContext } from "../hooks/useAuthContext";
import {Link, useNavigate} from "react-router-dom"

function Navbar() {
    const [existingListingId, setExistingListingId] = useState(null);

	const navRef = useRef();
    const navigate = useNavigate();
	const {logout} = useLogout()
	const handleLogout = () => {
		logout()
	}
	const {user} = useAuthContext()

	const showNavbar = () => {
		navRef.current.classList.toggle(
			"responsive_nav"
		);
	};

    
    const checkListing = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `/api/listings?userEmail=${user.email}`);
            const existingListing = await response.json();
            if (Array.isArray(existingListing) && existingListing.length > 0) {
                setExistingListingId(existingListing[0]._id);
            } else {
                setExistingListingId(null);
            }
        } catch (error) {
            console.log("Can't fetch listing because: ", error);
        }
    };

    useEffect(() => {
        if(user){
            checkListing();
        }
    }, [user]); // The effect will re-run if the user changes
    
    /*
    const handleListingClick = () => {
        if (existingListingId) {
            navigate('/listing/' + existingListingId);
        } else {
            navigate('/create-your-deathcare-directory-free-listing');
        }
    };
    */
    const listingHref = existingListingId 
    ? `/listing/${existingListingId}` 
    : '/listservice'



	return (
        <header>
            <Link to="/">
            <img src={require("../assets/clioLogo.png")} width="180" height="77"/>
            </Link>
            <nav ref={navRef}>
                <a href="/deathcare-directory">Directory</a>

                <a href="/end-of-life-community-forum">Community Forum</a>

                <a href="/community-calendar">Community Calendar</a>

                <div className="dropdown">
                     <button className="dropbtn">Resources</button>
                     <div className="dropdown-content">
                         <a href="/death-and-dying-glossary">Glossary</a>
                         <a href="/caregiver-resources">Caregiver Resources</a>
                         <a href="/eol-careers">End-of-Life Careers</a>
                         <a href="/about">About Us</a>
                     </div>
                 </div>
                
                


                <a href="/create-your-deathcare-directory-free-listing">List Your Service</a>

                <div className="user-info">
                    {!user &&(
                        <a href="/login">Login</a>
                    )}
                    {user && (

                    <div>
                        <div className="dropdown">
                            <button className="dropbtn"><span className="navUsername">{user.username}  |</span></button>
                            <div className="dropdown-content">
                                <a href={listingHref}>Listing</a>
                                <a href={`/user/${user.username}`}>User Profile</a>
                            </div>
                        </div>
                        

                        <a href="/" className="logout-btn" onClick={handleLogout}><b>Logout</b></a>

                    </div>
                    
                    
                    )}
                </div>
                <button
                        className="nav-btn nav-close-btn"
                        onClick={showNavbar}>
                        <FaTimes />
                </button>



            </nav>
            <button
                className="nav-btn"
                onClick={showNavbar}>
                <FaBars />
            </button>
        </header>
    );
}

export default Navbar;