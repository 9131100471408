import { createContext, useReducer} from "react";

export const CommentContext = createContext()

export const commentReducer = (state, action) => {
    switch(action.type) {
        case 'SET_COMMENTS':
            return {
                comments: action.payload
            }
        case 'SET_COMMENT':
            console.log(action.payload);
            const updatedCommentId = action.payload.comment._id;  // Adjusted this line
            console.log(updatedCommentId)
            const updatedComments = state.comments.map((comment) => {
                if (comment._id === updatedCommentId) return action.payload.comment;  // Adjusted this line
                return comment;
            });
            return {
                ...state,
                comments: updatedComments
            };
        case 'CREATE_COMMENT':
            console.log("In comment create: ", state.comments)
            return{
                comments: [action.payload, ...(state.comments|| [])]
            }
        case 'DELETE_COMMENT':
            return{
                comments: state.comments.filter((l)=>l._id !== action.payload._id)
            }
        case 'FILTER_COMMENTS':
            return {
                comments: action.payload
            }
        default:
            return state
    }
}

export const CommentContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(commentReducer, {
        comments: []
    })

    return(
        <CommentContext.Provider value={{...state, dispatch}}>
            { children }
        </CommentContext.Provider>
    )
}