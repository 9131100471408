import { createContext, useReducer} from "react";

export const ListingsContext = createContext()

export const listingsReducer = (state, action) => {
    switch(action.type) {
        case 'SET_LISTINGS':
            return {
                listings: action.payload
            }
        case 'CREATE_LISTING':
            return{
                listings: [action.payload, ...(state.listings|| [])]
            }
        case 'DELETE_LISTING':
            return{
                listings: state.listings.filter((l)=>l._id !== action.payload._id)
            }
            case 'FILTER_LISTING':
                const json2 = JSON.parse(action.payload);
                console.log(json2);

                // Helper functions for filtering
                const isVirtualOrHybrid = (l) => ['Virtual', 'Hybrid'].includes(l.serviceLocation);
                const matchCategory = (l) => !json2['category'] || l.categories.includes(json2['category']);
                const matchSkill = (l) => !json2['skill'] || l.skills.includes(json2['skill']);
                const matchCity = (l) => isVirtualOrHybrid(l) || !json2['city'] || l.city === json2['city'];
                const matchState = (l) => isVirtualOrHybrid(l) || !json2['state'] || l.state === json2['state'];
                const matchCountry = (l) => isVirtualOrHybrid(l) || !json2['country'] || l.country === json2['country'];
                const matchLoc = (l) => !json2['loc'] || l.serviceLocation === json2['loc'] || l.serviceLocation === 'Hybrid';

                const sortCity = (l) => !json2['city'] || l.city === json2['city'];
                const sortState = (l) => !json2['state'] || l.state === json2['state'];
                const sortCountry = (l) => !json2['country'] || l.country === json2['country'];

                // Combined filter
                const filteredListings = state.listings.filter(l => 
                    matchCategory(l) && 
                    matchSkill(l) &&
                    matchCity(l) && 
                    matchState(l) && 
                    matchCountry(l) && 
                    matchLoc(l)
                );  
                
                
                let localListings = [];
                
                if(json2['state'] && json2['country'] && json2['city']){
                    localListings = state.listings.filter(l =>
                        ((l.serviceLocation === 'In-Person' || (l.serviceLocation === 'Hybrid')) && l.city === json2['city'] && l.state === json2['state'] && l.country === json2['country']) &&
                        matchCategory(l) && 
                        matchSkill(l)
                    );
                }
                

                let showingOnlyVirtual = !!(localListings.length === 0 && json2['state'] && json2['country'] && json2['city']);
                console.log("showingvirtual: ", showingOnlyVirtual)
                console.log("local listings: ", localListings)
                /*
                const localListings = state.listings.filter(l =>
                    (l.serviceLocation === 'In-Person' || (l.serviceLocation === 'Hybrid' && l.city === json2['city'] && l.state === json2['state'] && l.country === json2['country'])) &&
                    matchCategory(l) && 
                    matchSkill(l)
                );
            
                const virtualListings = state.listings.filter(l =>
                    (l.serviceLocation === 'Virtual' || (l.serviceLocation === 'Hybrid' && (l.city != json2['city'] || l.state != json2['state'] || l.country === json2['country']))) &&
                    matchCategory(l) && 
                    matchSkill(l)
                );
                let finalListings = localListings.length > 0 ? localListings : virtualListings;
                let showingOnlyVirtual = localListings.length === 0;
                
                return {
                    listings: finalListings,
                    showingOnlyVirtual: showingOnlyVirtual
                };
                */
                
                // Sorting function
                const sortOrder = (l) => {
                    if (l.serviceLocation === 'In-Person') return 1;
                    if (l.serviceLocation === 'Hybrid' && sortCity(l) && sortState(l) && sortCountry(l)) return 2;
                    if (l.serviceLocation === 'Virtual' && matchCity(l) && sortState(l) && sortCountry(l)) return 3;
                    return 4;
                };
            
                // Sort the filtered listings
                const sortedListings = filteredListings.sort((a, b) => sortOrder(a) - sortOrder(b));
                return {
                    listings: sortedListings,
                    showingOnlyVirtual: showingOnlyVirtual
                };
                 /**/
            
        default:
            return state
    }
}

export const ListingsContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(listingsReducer, {
        listings: []
    })

    return(
        <ListingsContext.Provider value={{...state, dispatch}}>
            { children }
        </ListingsContext.Provider>
    )
}

